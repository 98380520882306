import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { bobaService } from '../services'
import { AppThunkConfig } from '../../store'
import { ApiRequest, AppError } from '../../AppTypes'
import { OutOfServiceError } from '../services/errors'
import { internalServerErrorAppError } from './common'
import 'dayjs/locale/ja'

const service = bobaService()

type State = {
  isActive: boolean
} & ApiRequest

const initialState: State = {
  isActive: false,
  apiStatus: 'Initial',
  error: undefined,
}

const handleError = (err: Error): AppError => {
  if (err instanceof OutOfServiceError) {
    const { nextActiveTime } = err
    let msg: string
    if (nextActiveTime) {
      dayjs.locale('ja')
      msg = `現在、ご利用時間外です。\n次回利用可能時間: ${dayjs(
        nextActiveTime
      ).format('YYYY年MM月DD日 HH:mm')}`
    } else {
      msg = `只今メンテナンス中です。`
    }
    return {
      needReturnTop: false,
      description: msg,
      title: 'エラー',
    }
  }
  return internalServerErrorAppError
}

export const getActive = createAsyncThunk<void, void, AppThunkConfig>(
  'active/get',
  async (_, { getState, rejectWithValue }) => {
    try {
      return await service.getActive(getState())
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)
const activeSlice = createSlice({
  name: 'active',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getActive.pending, () => {
      return {
        ...initialState,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(getActive.fulfilled, (state) => {
      return {
        ...state,
        apiStatus: 'Success',
        isActive: true,
      }
    })
    builder.addCase(getActive.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: 'Failure',
        isActive: false,
        error: action.payload,
      }
    })
  },
})

export default activeSlice
