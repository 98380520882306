import dayjs from 'dayjs'
import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'
import { ModeEnum } from '../../../features/mode/modeSlice'
import { normalMode, resentMode, trainingMode } from './message'

type Props = {
  mode: ModeEnum
  date?: Date
}

const ModeHeader: React.FC<Props> = ({ mode, date }: Props) => {
  dayjs.locale('ja')

  const modeTitle = (): string => {
    switch (mode) {
      case ModeEnum.Training:
        return trainingMode
      case ModeEnum.Resent:
        return resentMode
      default:
        return normalMode
    }
  }

  return (
    <div className={clsx(styles.mode_header, styles[mode])}>
      <div className={styles.title_wrapper}>
        {date && (
          <>
            <p className={styles.title}>売上日</p>
            <p className={styles.date}>
              {dayjs(date).format('YYYY年MM月DD日 (ddd)')}
            </p>
          </>
        )}
      </div>
      <p className={clsx(styles.mode, styles[mode])}>{modeTitle()}</p>
    </div>
  )
}

export default ModeHeader
