export const notionText = `カメラをQRコードに
向けてください`

export const confirmTenantText = `こちらの店舗で
間違いないですか？`

export const okButton = 'OK'
export const recapture = '再撮影する'
export const reAuth = `カメラが
起動しない方`

export const cameraAuthErrorTitle = `端末のカメラ権限が拒否されています。
手順に従ってカメラ権限を許可してください。`
export const cameraAuthErrorButton = '手順を確認する'
export const goToTop = 'トップ画面に戻る'
