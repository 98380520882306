import React, { Dispatch, RefObject, useEffect, useState } from 'react'
import jsQR from 'jsqr'

export default (
  initialVideoRef: RefObject<HTMLVideoElement> | null,
  canvasRef: RefObject<HTMLCanvasElement>,
  scanEnabled: boolean,
  onScanned?: (result: string) => void,
  onScanError?: (error: Error) => void
): Dispatch<RefObject<HTMLVideoElement> | null> => {
  const [videoRef, setVideoRef] = useState<RefObject<HTMLVideoElement> | null>(
    null
  )
  const requestRef = React.useRef<number>(0)
  const [qrCode, setQrCode] = useState<string | undefined>()
  useEffect((): (() => void) => {
    const scan = (): void => {
      if (!scanEnabled) {
        return
      }
      if (canvasRef.current && videoRef && videoRef.current) {
        try {
          const canvas = canvasRef.current.getContext('2d')
          if (!canvas) {
            return
          }
          // eslint-disable-next-line no-param-reassign
          canvasRef.current.height = videoRef.current.videoHeight
          // eslint-disable-next-line no-param-reassign
          canvasRef.current.width = videoRef.current.videoWidth
          canvas.drawImage(
            videoRef.current,
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height
          )
          const imageData = canvas.getImageData(
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height
          )
          const code = jsQR(imageData.data, imageData.width, imageData.height, {
            inversionAttempts: 'dontInvert',
          })
          setQrCode(code?.data)
        } catch (err) {
          if (onScanError && !(err instanceof DOMException) && scanEnabled) {
            onScanError(err as Error)
          }
        } finally {
          requestRef.current = requestAnimationFrame(scan)
        }
      }
    }
    requestRef.current = requestAnimationFrame(scan)
    return (): void => cancelAnimationFrame(requestRef.current)
  }, [canvasRef, videoRef, scanEnabled, onScanError, onScanned])

  useEffect(() => {
    if (onScanned && qrCode && scanEnabled) {
      onScanned(qrCode)
    }
  }, [qrCode])
  return setVideoRef
}
