export const titleText = (count: number): string => {
  if (count > 1) {
    return `${count}台目レジの以下の項目を
入力してください`
  }
  return `以下の項目を入力してください`
}
export const irregularTitleText = `必要項目を入力してください
※発生しなかった項目は「０」で入力`
export const titleIrregular = `必要項目を入力してください
※発生しなかった項目は「０」で入力`

export const submitBtnText = `確認`

export const diffDescription = `差分が発生しているため、
入力値を確認してください。`

export const lengthError = (length: number): string => {
  return `${length}桁以内で入力してください`
}
