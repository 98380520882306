import clsx from 'clsx'
import React from 'react'
import styles from './style.module.scss'

type CaptureOverlayProps = {
  src?: string
  full?: boolean
}

type Props = CaptureOverlayProps & React.StyledProps

const CaptureOverlay: React.StyledFC<Props> = ({
  src,
  full,
  style,
  className,
}: Props) => {
  return (
    <div style={style} className={clsx(className, styles.container)}>
      <div className={clsx(styles.overlay, full && styles.full)}>
        {!full && (
          <>
            <div className={clsx(styles.border, styles.left)} />
            <div className={clsx(styles.border, styles.right)} />
          </>
        )}
      </div>
      {src && <img className={styles.img} src={src} alt="confirm-img" />}
    </div>
  )
}

export default CaptureOverlay
