import React from 'react'
import clsx from 'clsx'
import Typography from '../../atoms/Typography'
import styles from './style.module.scss'
import Icon, { IconNames } from '../../atoms/Icon'

type LargeTitleDescriptionProps = {
  title: string
  description: string
  color?: AppColor
  align?: 'left' | 'center'
  newLine?: boolean
  icon?: IconNames
}

type Props = LargeTitleDescriptionProps & React.StyledProps
const LargeTitleDescription: React.StyledFC<Props> = ({
  title,
  description,
  color = 'primary',
  align = 'left',
  newLine = true,
  icon,
  style,
  className,
}: Props) => {
  return (
    <div
      className={clsx(className, styles.container, newLine && styles.newLine)}
      style={style}
    >
      {icon && <Icon icon={icon} className={styles.icon} />}
      <Typography
        color={color}
        className={styles.title}
        variant="largeTitle"
        align={align}
        newLine
      >
        {title}
      </Typography>
      <Typography
        color={color}
        className={styles.description}
        variant="description"
        align={align}
        newLine
      >
        {description}
      </Typography>
    </div>
  )
}

export default LargeTitleDescription
