import React from 'react'
import clsx from 'clsx'
import Typography from '../../atoms/Typography'
import BgWrapper from '../../atoms/BgWrapper'
import styles from './style.module.scss'

type CameraNoteProps = {
  text: string
}

type Props = CameraNoteProps & React.StyledProps
const CameraNote: React.StyledFC<Props> = ({
  text,
  style,
  className,
}: Props) => {
  return (
    <div className={clsx(className, styles.container)}>
      <BgWrapper
        color="gray"
        style={style}
        variant="rounded"
        className={styles.wrapper}
      >
        <Typography
          color="white"
          variant="note"
          align="center"
          newLine
          className={styles.text}
        >
          {text}
        </Typography>
      </BgWrapper>
    </div>
  )
}

export default CameraNote
