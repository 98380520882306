import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'

type DarkerImageProps = {
  src: string
  alt: string
}
type Props = DarkerImageProps & React.StyledProps
const ImageWithSupportLine: React.StyledFC<Props> = ({
  src,
  alt,
  style,
  className,
}: Props) => {
  return (
    <div className={clsx(styles.container, className)} style={style}>
      <img src={src} alt={alt} className={styles.img} />
    </div>
  )
}

export default ImageWithSupportLine
