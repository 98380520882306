import React from 'react'
import clsx from 'clsx'
import ModeHeader from '../../molecules/ModeHeader'
import styles from './style.module.scss'
import { ModeEnum } from '../../../features/mode/modeSlice'

type Props = {
  mode?: ModeEnum
  date?: Date
} & React.StyledProps &
  React.ChildrenProps

const FullSize: React.FC<Props> = ({
  mode = ModeEnum.Normal,
  date,
  className,
  children,
}: Props) => {
  return (
    <div className={clsx(className, styles.mode, styles[mode])}>
      <ModeHeader mode={mode} date={date} />
      <div className={clsx(styles.modeWrapper)}>{children}</div>
    </div>
  )
}
export default FullSize
