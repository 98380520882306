import React, { ReactElement } from 'react'
import Div100vh from '../../components/atoms/Div100vh'
import Button from '../../components/atoms/Button'
import styles from './styles.module.scss'
import Space from '../../components/atoms/Space'
import Typography from '../../components/atoms/Typography'
import { buttonTitle, description, title } from './messages'
import { topPath } from '../../routes/paths'
import useAppHistory from '../../utils/useAppHistory'

function EmailSendCompletion(): ReactElement {
  const history = useAppHistory()

  const handleButtonClick = (): void => {
    history.push(topPath)
  }
  return (
    <Div100vh className={styles.root} disabledModeWrapper>
      <div className={styles.container}>
        <Typography className={styles.title}>{title}</Typography>
        <Space size="52px" orientation="vertical" />
        <div className={styles.content}>
          <Typography>{description}</Typography>
        </div>
        <Space size="144px" orientation="vertical" />
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.button}
            variant="contained"
            color="green"
            onClick={handleButtonClick}
          >
            {buttonTitle}
          </Button>
        </div>
      </div>
    </Div100vh>
  )
}

export default EmailSendCompletion
