import React from 'react'
import clsx from 'clsx'
import ListItem from '../../atoms/ListItem'
import styles from './style.module.scss'

interface OrderListProps<T> {
  items: T[]
  color?: AppColor
  space?: string
}

type Props<T> = OrderListProps<T> & React.StyledProps
type MyComponentI<T> = React.StyledFC<Props<T>>
const CircleIndexOrderList: MyComponentI<string | number> = ({
  items,
  color = 'white',
  space = '18px',
  style,
  className,
}: Props<string | number>) => {
  return (
    <ol
      className={clsx(styles.list, styles[color], className)}
      style={style}
      data-testid="list"
    >
      {items.map((item, idx) => (
        <ListItem
          key={item}
          style={(idx + 1 !== items.length && { marginBottom: space }) || {}}
          className={styles.item}
        >
          {item}
        </ListItem>
      ))}
    </ol>
  )
}
export default CircleIndexOrderList
