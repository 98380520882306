import dayjs from 'dayjs'

export const title = (date: Date, count: number): string => {
  return `${count}台目レジの
【${dayjs(date).format('YYYY年MM月DD日')}】の
精算レシートの写真を
アップロードしていきましょう。`
}

export const description = `写真は複数枚をアップロードできます。
文字が小さくなりすぎないように、
分けて撮影しましょう。`

export const buttonText = 'つぎへ'
export const secondButtonText = '戻る'
