export const title = 'PCでのご利用について'

export const description =
  '送信されたメールを確認し、手順に従ってログインを進めてください'

export const buttonTitle = `次へすすむ`

export const guideNotes = [
  'メールを送ります',
  'メールに送信されたリンクをクリック',
  'リンクからログインする',
  'Zero PDFの利用開始。2回目以降はPCのみでログインできる',
]
