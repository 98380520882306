import React, { ReactElement } from 'react'
import Typography from '../../components/atoms/Typography'
import { buttonTitle, description, guideNotes, title } from './message'
import Space from '../../components/atoms/Space'
import CircleIndexOrderList from '../../components/molecules/CircleIndexOrderList'
import styles from './styles.module.scss'
import Button from '../../components/atoms/Button'
import useAppHistory from '../../utils/useAppHistory'
import { inputEmailPath, reportCommandPath } from '../../routes/paths'
import Div100vh from '../../components/atoms/Div100vh'
import { cancelButtonTitle } from '../InputEmail/message'

function PcGuide(): ReactElement {
  const history = useAppHistory()

  const handleClick = (): void => {
    history.push(inputEmailPath)
  }

  const handleClickCancel = (): void => {
    history.push(reportCommandPath)
  }

  return (
    <Div100vh
      className={styles.root}
      style={{ backgroundColor: 'black' }}
      disabledModeWrapper
    >
      <div className={styles.container}>
        <Typography className={styles.text} align="left" color="white" newLine>
          {title}
        </Typography>
        <Space size="12px" orientation="vertical" />
        <Typography
          className={styles.description}
          align="left"
          color="white"
          newLine
        >
          {description}
        </Typography>
        <Space size="40px" orientation="vertical" />
        <CircleIndexOrderList items={guideNotes} color="white" />
        <Space size="60px" orientation="vertical" />
        <div className={styles.buttonWrapper}>
          <Button className={styles.button} color="green" onClick={handleClick}>
            {buttonTitle}
          </Button>
        </div>
        <div className={styles.cancelButtonWrapper}>
          <div className={styles.cancelButton} onClick={handleClickCancel}>
            {cancelButtonTitle}
          </div>
        </div>
      </div>
    </Div100vh>
  )
}

export default PcGuide
