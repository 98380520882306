import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiRequest } from '../../AppTypes'
import { bobaService } from '../services'
import { AppThunkConfig } from '../../store'
import { enableLeaveConfirmation } from '../../utils'

const service = bobaService()

type State = {
  email: string
} & ApiRequest

const initialState: State = {
  email: '',
  apiStatus: 'Initial',
  error: undefined,
}

// Error Handling

export type tenantUserSignUpParam = {
  email: string
}

export const tenantUserSignUp = createAsyncThunk<
  void,
  tenantUserSignUpParam,
  AppThunkConfig
>('/app/sign-up', async (args, { getState }) => {
  try {
    return await service.tenantUserSignUp(
      {
        email: args.email,
      },
      getState()
    )
  } catch (err) {
    return undefined
  }
})

const tenantUserSlice = createSlice({
  name: 'tenantUser',
  initialState,
  reducers: {
    clearApiRequest: (state: State): State => {
      return {
        ...state,
        apiStatus: 'Initial',
        error: undefined,
      }
    },
    updateEmail: (state: State, { payload }: PayloadAction<string>): State => {
      return {
        ...state,
        email: payload,
      }
    },
    clearEmail: (): State => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(tenantUserSignUp.pending, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(tenantUserSignUp.fulfilled, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Success',
      }
    })
    builder.addCase(tenantUserSignUp.rejected, (state, action) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Failure',
        error: action.payload,
      }
    })
  },
})

export const {
  clearApiRequest,
  updateEmail,
  clearEmail,
} = tenantUserSlice.actions
export default tenantUserSlice
