import React, { RefObject, useRef } from 'react'
import clsx from 'clsx'
import CameraVideo from '../../atoms/CameraVideo'
import styles from './style.module.scss'
import useQRCodeReader from './hooks/useQRCodeReader'

type QRCodeCameraVideoProps = {
  videoId?: string
  onScanned?: (result: string) => void
  onScanError?: (error: Error) => void
  onCameraAuthError?: () => void
  scanEnabled?: boolean
}

type Props = QRCodeCameraVideoProps & React.StyledProps

const QRCodeCameraVideo: React.StyledFC<Props> = ({
  videoId,
  onScanned,
  onScanError,
  onCameraAuthError,
  scanEnabled = true,
  style,
  className,
}: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const setVideoRef = useQRCodeReader(
    null,
    canvasRef,
    scanEnabled,
    onScanned,
    onScanError
  )

  const handleCanPlay = (element: RefObject<HTMLVideoElement>): void => {
    setVideoRef(element)
  }

  const handleCameraError = (err: Error): void => {
    if (onCameraAuthError && err.message === 'NotAllowedError') {
      onCameraAuthError()
    }
  }

  return (
    <div
      className={clsx(className, styles.qrcode, styles.container)}
      style={{ ...style }}
    >
      <CameraVideo
        id={videoId}
        onCanPlay={handleCanPlay}
        className={styles.video}
        onCameraError={handleCameraError}
      />
      <canvas ref={canvasRef} style={{ width: '100%', display: 'none' }} />
    </div>
  )
}

export default QRCodeCameraVideo
