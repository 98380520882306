import React from 'react'
import clsx from 'clsx'
import Button from '../../atoms/Button'
import styles from './style.module.scss'
import Typography from '../../atoms/Typography'
import Icon from '../../atoms/Icon'
import ImageWithSupportLine from '../../molecules/ImageWithSupportLine'

type CaptureConfirmItemProps = {
  buttonText: string
  imgSrc: string
  imgAlt: string
  onClick?: () => void
  onClickClose?: () => void
}

type Props = CaptureConfirmItemProps & React.StyledProps
const CaptureConfirmItem: React.StyledFC<Props> = ({
  style,
  className,
  imgSrc,
  imgAlt,
  buttonText,
  onClick,
  onClickClose,
}: Props) => {
  return (
    <div className={clsx(className, styles.container)} style={style}>
      <div className={styles.wrapper}>
        <ImageWithSupportLine
          src={imgSrc}
          alt={imgAlt}
          className={styles.image}
        />
        <Typography color="white" className={styles.altText}>
          {imgAlt}
        </Typography>
        <Button
          variant="rounded"
          color="primary"
          onClick={onClick}
          className={styles.button}
        >
          {buttonText}
        </Button>
        <Icon
          icon="close"
          color="white"
          onClick={onClickClose}
          className={styles.close}
        />
      </div>
    </div>
  )
}

export default CaptureConfirmItem
