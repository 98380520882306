import React, { useState } from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'
import { FormTypeEnum } from '../../../features/services/api'

type InputFormProps = {
  id: string
  name: string
  variant: FormTypeEnum
  defaultValue?: string
  placeholder?: string
  pattern?: string
  onChange?: (name: string, value: string) => void
  onEnter?: () => void
}

const handleNumber = (input: string): [newValue, changedValue] => {
  if (input === '-') {
    return ['-', '']
  }
  return [input, input]
}

const handleText = (input: string): [newValue, changedValue] => {
  return [input, input]
}

type changedValue = string
type newValue = string

type valueHandler = (input: string) => [newValue, changedValue]

type VariantMode = {
  type: string
  inputMode:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
  handler: valueHandler
}

const variantMapper = {
  text: {
    type: 'text',
    inputMode: 'text',
    handler: handleText,
  },
  number: {
    type: 'number',
    // inputMode: 'numeric',
    handler: handleNumber,
  },
  // todo
  date: {
    type: 'number',
    // inputMode: 'numeric',
    handler: handleNumber,
  },
  // todo
  email: {
    type: 'email',
    // inputMode: 'numeric',
    handler: handleNumber,
  },
}

type Props = InputFormProps & React.StyledProps
// TODO: 基本はnumber & requiredしか対応してません
const InputForm: React.StyledFC<Props> = ({
  id,
  name,
  variant,
  defaultValue = '',
  placeholder = '',
  pattern,
  onChange,
  onEnter,
  style,
  className,
}: Props) => {
  const map = variantMapper[variant] as VariantMode
  const [v, setValue] = useState<string | undefined>(defaultValue)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const input = event.target.value.trim()
    if (!new RegExp(pattern || '.*').exec(input)) {
      setValue(input.slice(0, input.length - 1))
      return
    }
    const [newValue, changedValue] = map.handler(input)
    setValue(newValue)
    if (changedValue === undefined) {
      return
    }
    if (onChange) {
      onChange(name, changedValue)
    }
  }

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === 'Enter' && onEnter) {
      onEnter()
    }

    if (
      variant === 'number' &&
      !(event.charCode >= 48 && event.charCode <= 57)
    ) {
      event.preventDefault()
    }
  }

  return (
    <input
      data-testid="input-form"
      inputMode={map.inputMode}
      id={id}
      name={name}
      placeholder={placeholder}
      pattern={pattern}
      type={map.type}
      style={style}
      value={v}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      className={clsx(className, styles.input)}
    />
  )
}

export default InputForm
