import React from 'react'
import clsx from 'clsx'

type SpaceProps = {
  size: string
  orientation?: 'vertical' | 'horizontal'
}

type Props = SpaceProps & React.StyledProps
const Space: React.StyledFC<Props> = ({
  orientation = 'vertical',
  size = '0',
  style,
  className,
}: Props) => {
  const space =
    orientation === 'vertical' ? { marginTop: size } : { marginLeft: size }
  return (
    <div
      style={{ ...space, ...style }}
      data-testid="space"
      className={clsx(className)}
    />
  )
}

export default Space
