import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'
import Icon from '../../atoms/Icon'
import CircleIndexOrderList from '../../molecules/CircleIndexOrderList'
import Space from '../../atoms/Space'
import Typography from '../../atoms/Typography'

type TopFlowProps = {
  title: string
  items: string[]
}

type Props = TopFlowProps & React.StyledProps

const TopFlow: React.StyledFC<Props> = ({
  title,
  items,
  style,
  className,
}: Props) => {
  return (
    <div
      data-testid="top-flow"
      className={clsx(className, styles.container)}
      style={style}
    >
      <Space orientation="vertical" size="48px" />
      <Icon icon="light" color="white" className={styles.icon} />
      <Space orientation="vertical" size="27px" />
      <span className={styles.title}>{title}</span>
      <Space orientation="vertical" size="33px" />
      <CircleIndexOrderList items={items} space="17px" />
      <Space orientation="vertical" size="64px" />
      <Typography
        variant="span"
        className={styles.version}
        color="white"
      >{`ver${process.env.REACT_APP_VERSION}`}</Typography>
    </div>
  )
}

export default TopFlow
