import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'
import Typography from '../../atoms/Typography'
import BgWrapper from '../../atoms/BgWrapper'
import Button from '../../atoms/Button'

type ModalMessageProps = {
  message: string
  buttonTitle: string
  onClick: () => void
}

type Props = ModalMessageProps & React.StyledProps
const ModalMessage: React.StyledFC<Props> = ({
  message,
  buttonTitle,
  onClick,
  style,
  className,
}: Props) => {
  return (
    <BgWrapper
      color="white"
      variant="rounded"
      data-testid="modal-indicator"
      className={clsx(className, styles.container)}
      style={style}
    >
      <Typography
        variant="description"
        align="center"
        className={styles.message}
      >
        {message}
      </Typography>
      <Button
        onClick={onClick}
        color="primary"
        variant="contained"
        className={styles.button}
      >
        {buttonTitle}
      </Button>
    </BgWrapper>
  )
}

export default ModalMessage
