import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { Tenant } from '../../../features/services/api'

export default (): Tenant | null => {
  const { tenant } = useSelector((state: RootState) => state.auth)
  const [t, setTenant] = useState(tenant)
  useEffect(() => {
    if (tenant) {
      setTenant(tenant)
    }
  }, [tenant, setTenant])
  return t
}
