/* eslint-disable no-return-assign */
import React from 'react'
import clsx from 'clsx'
import ReactModal from 'react-modal'
import ModalIndicator from '../../molecules/ModalIndicator'
import styles from './style.module.scss'

type LoadingModalProps = {
  text?: string
  isOpen: boolean
  style?: ReactModal.Styles
}

type Props = React.StyledProps & LoadingModalProps
const LoadingModal: React.StyledFC<Props> = ({
  text,
  isOpen = false,
  style,
  className,
}: Props) => {
  return (
    <ReactModal
      isOpen={isOpen}
      className={clsx(className, styles.container)}
      style={style}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      overlayClassName={styles.overlay}
      onAfterOpen={(): string => (document.body.style.overflow = 'hidden')}
      onAfterClose={(): string => (document.body.style.overflow = 'unset')}
    >
      <ModalIndicator className={styles.modal} text={text} />
    </ReactModal>
  )
}

export default LoadingModal
