import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'

type CaptureButtonProps = {
  onClick?: () => void
  disabled?: boolean
}

type Props = CaptureButtonProps & React.StyledProps
const CaptureButton: React.StyledFC<Props> = ({
  onClick,
  style,
  disabled = false,
  className,
}: Props) => {
  return (
    <div
      onClick={onClick}
      role="button"
      data-testid="capture-button"
      style={style}
      className={clsx(className, styles.outer, disabled && styles.disabled)}
      tabIndex={0}
      onKeyDown={undefined}
    >
      <div className={clsx(styles.inner, disabled && styles.disabled)}>
        Capture Button
      </div>
    </div>
  )
}

export default CaptureButton
