import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'
import CaptureButton from '../../atoms/CaptureButton'
import Button from '../../atoms/Button'
import IconWithButton from '../IconWithButton'

type CameraFooterProps = {
  onCenterClick?: () => void
  rightButtonText: string
  leftButtonText: string
  onRightClick?: () => void
  onLeftClick?: () => void
  disabled?: boolean
  isVertical?: boolean
}

type Props = CameraFooterProps & React.StyledProps & React.ChildrenProps
const CameraFooter: React.StyledFC<Props> = ({
  onCenterClick,
  rightButtonText,
  leftButtonText,
  onRightClick,
  onLeftClick,
  disabled = false,
  isVertical = false,
  style,
  className,
}: Props) => {
  return (
    <div className={clsx(className, styles.container)} style={style}>
      <div className={styles.buttons}>
        <div className={styles.button}>
          <IconWithButton
            icon={isVertical ? 'vertical' : 'horizontal'}
            variant="rounded"
            buttonColor="white"
            color="blue"
            text={leftButtonText}
            onClick={onLeftClick}
          />
        </div>
        <div className={styles.center}>
          <CaptureButton
            disabled={disabled}
            onClick={onCenterClick}
            className={styles.centerButton}
          />
        </div>
        <div className={styles.button}>
          <Button
            className={styles.right}
            variant="rounded"
            color="gray"
            onClick={onRightClick}
          >
            {rightButtonText}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CameraFooter
