export const headerTitle = 'テナント売上報告ログイン画面'
export const middleTitle = '今日もお疲れ様でした'

export const middleSubTitle = `売上報告を行いましょう`
export const flowTitle = '売上報告の流れ'
export const flows = [
  '精算レシートを撮影します',
  '売上を入力します',
  '写真データと入力内容を照合し、チェックします',
]
export const qrCaptureButtonTitle = 'QR撮影でテナント認証へすすむ'
export const faqButtonTitle = 'よくある質問'
export const bugReportButtonTitle = '不具合報告'
export const releaseNoteButtonTitle = 'リリースノート'

export const internalErrorTitle = '【重要なお知らせ】'
export const isInternalServerErrorTittle = `
ただいまアプリを利用することができません

想定される原因
1. 機内モードやネットワークにつながっていない
2. Zeroシステムトラブルが発生している

 ネットワークに問題がない場合は、
 施設管理者もしくは、施設担当者に従って
 売上報告を行なってください。`

export const forceReloadTitle = `一定時間操作を
確認できませんでした`
export const forceReloadDescription = `アプリを安定稼働させるため、
お手数ですが一度アプリを
終了してください`
