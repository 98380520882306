import React from 'react'
import clsx from 'clsx'
import BgWrapper from '../../atoms/BgWrapper'
import styles from './style.module.scss'
import Typography from '../../atoms/Typography'
import Button from '../../atoms/Button'

type ErrorModalProps = {
  title?: string
  description?: string
  buttonTitle?: string
  buttonTitleSecondary?: string
  onClick?: () => void
  onClickSecondary?: () => void
}

type Props = React.StyledProps & ErrorModalProps
const ModalError: React.StyledFC<Props> = ({
  title,
  description,
  buttonTitle,
  buttonTitleSecondary,
  onClick,
  onClickSecondary,
  className,
}: Props) => {
  return (
    <BgWrapper
      color="white"
      variant="rounded"
      className={clsx(className, styles.container)}
    >
      {title && (
        <Typography
          variant="middleTitle"
          align="center"
          newLine
          className={styles.title}
        >
          {title}
        </Typography>
      )}
      {description && (
        <Typography
          variant="description"
          align="center"
          newLine
          className={styles.description}
        >
          {description}
        </Typography>
      )}
      <div className={styles.buttons}>
        {buttonTitle && (
          <Button
            onClick={onClick}
            color="primary"
            variant="contained"
            className={styles.button}
          >
            {buttonTitle}
          </Button>
        )}
        {buttonTitleSecondary && (
          <Button
            onClick={onClickSecondary}
            color="white"
            variant="contained"
            className={styles.button}
          >
            {buttonTitleSecondary}
          </Button>
        )}
      </div>
    </BgWrapper>
  )
}

export default ModalError
