import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import clsx from 'clsx'
import useAppHistory from '../../utils/useAppHistory'
import { RootState } from '../../store'
import Div100vh from '../../components/atoms/Div100vh'
import AppHeader from '../../components/organisms/AppHeader'
import useTenant from '../hooks/useTenant'
import {
  reportCommandPath,
  topWithClearDataPath,
  tenantNotificationBySalesDatesPath,
} from '../../routes/paths'
import style from './styles.module.scss'
import Typography from '../../components/atoms/Typography'
import { title } from './message'
import Button from '../../components/atoms/Button'
import {
  clearAPIStatus,
  clearNotificationsBySalesDate,
  clearSelectedSalesDateId,
  getNotificationsBySalesDate,
  patchNotifications,
} from '../../features/network/notificationsSlice'
import ErrorModal from '../../components/organisms/ErrorModal'
import { AppError } from '../../AppTypes'
import UnreadBadge from '../../components/atoms/UnreadBadge'
import LoadingModal from '../../components/organisms/LoadingModal'

function NotificationsBySalesDate(): ReactElement {
  dayjs.locale('ja')

  const history = useAppHistory()
  const dispatch = useDispatch()
  const tenant = useTenant()

  const {
    notificationsBySalesDate,
    selectedSalesDateId,
    apiStatus,
    error,
  } = useSelector((state: RootState) => state.notifications)

  const getAndPatchNotifications = async (): Promise<void> => {
    if (!selectedSalesDateId) return

    const actionResult = await dispatch(
      getNotificationsBySalesDate({
        tenantId: tenant.id,
        salesDateId: selectedSalesDateId,
      })
    )

    if (
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      getNotificationsBySalesDate.fulfilled.match(actionResult)
    ) {
      const unreadNotificationIds = actionResult?.payload.notifications
        .filter((notification) => notification.isReadByTenant === false)
        .map((notification) => notification.id)

      // NOTE: 未読の通知がない場合は、Patch APIを呼ばない
      if (!unreadNotificationIds || unreadNotificationIds.length === 0) return

      dispatch(
        patchNotifications({
          tenantId: tenant.id,
          salesDateId: selectedSalesDateId,
          notificationIds: unreadNotificationIds,
        })
      )
    }
  }

  useEffect(() => {
    getAndPatchNotifications()
  }, [])

  const handleClickBackReportHistory = (): void => {
    dispatch(clearNotificationsBySalesDate())
    dispatch(clearSelectedSalesDateId())
    history.push(reportCommandPath)
  }

  const handleClickClose = (): void => {
    dispatch(clearNotificationsBySalesDate())
    dispatch(clearSelectedSalesDateId())
    history.push(tenantNotificationBySalesDatesPath)
  }

  const handleErrorModalButtonClick = (err: AppError): void => {
    if (err.needReturnTop) {
      history.push(topWithClearDataPath)
    } else {
      dispatch(clearAPIStatus())
      history.push(reportCommandPath)
    }
  }

  return (
    <Div100vh className={style.root} disabledModeWrapper>
      <AppHeader
        tenantName={tenant.tenantName}
        storeName={tenant.storeName}
        onClickBackReportHistory={handleClickBackReportHistory}
      />
      <div className={style.container}>
        <div>
          <Typography variant="largeTitle" align="left" bold>
            {title}
          </Typography>
          <Typography
            className={style.salesDate}
            variant="description"
            align="left"
          >
            売上日：
            {dayjs(notificationsBySalesDate?.salesDate).format(
              'YYYY.MM.DD(ddd)'
            )}
          </Typography>
          <Typography
            className={style.lastCreatedAt}
            variant="description"
            align="left"
          >
            最終受信日時：
            {dayjs(notificationsBySalesDate?.lastCreatedAt).format(
              'YYYY.MM.DD(ddd) HH:mm'
            )}
          </Typography>
        </div>
        {notificationsBySalesDate &&
          notificationsBySalesDate?.notifications?.map((notification) => (
            <div className={style.notification} key={notification.id}>
              <div className={style.createAtAndUnreadBadge}>
                <Typography
                  className={clsx(
                    style.createdAt,
                    notification.isReadByTenant && style.isReadByTenant
                  )}
                  variant="description"
                  align="left"
                >
                  受信日時：
                  {dayjs(notification.createdAt).format(
                    'YYYY.MM.DD(ddd) HH:mm'
                  )}
                </Typography>
                {!notification.isReadByTenant && (
                  <UnreadBadge className={style.unreadBadge} title="未読" />
                )}
              </div>
              <Typography
                className={clsx(
                  style.content,
                  notification.isReadByTenant && style.isReadByTenant
                )}
                variant="description"
              >
                {notification.content}
              </Typography>
            </div>
          ))}
        <div className={style.closeButtonWrapper}>
          <Button color="white" onClick={(): void => handleClickClose()}>
            確認して閉じる
          </Button>
        </div>
      </div>
      <LoadingModal isOpen={apiStatus === 'Progress'} />
      {error && (
        <ErrorModal
          isOpen={Boolean(error)}
          title={error.title}
          description={error.description}
          buttonTitle="OK"
          onClick={(): void => handleErrorModalButtonClick(error)}
        />
      )}
    </Div100vh>
  )
}

export default NotificationsBySalesDate
