import React, { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Div100vh from '../../components/atoms/Div100vh'
import useAppHistory from '../../utils/useAppHistory'
import Typography from '../../components/atoms/Typography'
import { buttonTitle, cancelButtonTitle, title } from './message'
import styles from './styles.module.scss'
import { inputEmailConfirmPath, pcGuidePath } from '../../routes/paths'
import Button from '../../components/atoms/Button'
import { updateEmail } from '../../features/network/tenantUserSlice'
import { RootState } from '../../store'

function InputEmail(): ReactElement {
  const history = useAppHistory()
  const dispatch = useDispatch()

  const { email } = useSelector((state: RootState) => state.tenantUser)

  const [emailState, setEmailState] = useState<string>(email)

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setEmailState(e.target.value)
  }

  const handleClickButton = (): void => {
    dispatch(updateEmail(emailState))
    history.push(inputEmailConfirmPath)
  }

  const handleClickCancel = (): void => {
    history.push(pcGuidePath)
  }

  return (
    <Div100vh className={styles.root} disabledModeWrapper>
      <div className={styles.container}>
        <Typography className={styles.title}>{title}</Typography>
        <div className={styles.textareaWrapper}>
          <textarea
            className={styles.textarea}
            onChange={handleChange}
            placeholder="メールアドレス"
            value={emailState}
          />
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.button}
            color="green"
            onClick={handleClickButton}
          >
            {buttonTitle}
          </Button>
        </div>
        <div className={styles.cancelButtonWrapper}>
          <div className={styles.cancelButton} onClick={handleClickCancel}>
            {cancelButtonTitle}
          </div>
        </div>
      </div>
    </Div100vh>
  )
}

export default InputEmail
