import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider } from 'react-redux'
import Modal from 'react-modal'
import { PersistGate } from 'redux-persist/integration/react'
import Routes from './routes'
import * as serviceWorker from './serviceWorker'
import store, { persistor } from './store'
import { SWUpdateDialog } from './components/organisms/UpdateNotification'
import ErrorBoundary from './containers/Bugsnag'

Modal.setAppElement('#root')

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
serviceWorker.register({
  onUpdate: (registration) => {
    if (registration.waiting) {
      ReactDOM.render(
        // eslint-disable-next-line react/jsx-no-undef
        <SWUpdateDialog registration={registration} />,
        document.querySelector('.SW-update-dialog')
      )
    }
  },
})
