import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'
import Button from '../../atoms/Button'
import Icon from '../../atoms/Icon'
import Typography from '../../atoms/Typography'
import IconWithButton from '../IconWithButton'

type CameraConfirmFooterProps = {
  centerText: string
  rightText: string
  leftText: string
  onClickCenter: () => void
  onClickRight: () => void
  onClickLeft: () => void
}

type Props = CameraConfirmFooterProps & React.StyledProps

const CameraConfirmFooter: React.StyledFC<Props> = ({
  centerText,
  rightText,
  leftText,
  onClickCenter,
  onClickRight,
  onClickLeft,
  className,
  style,
}: Props) => {
  return (
    <div style={style} className={clsx(className, styles.container)}>
      <div className={styles.buttons}>
        <div className={styles.buttonWrapper}>
          <div className={styles.buttonWithText}>
            <Button
              className={clsx(styles.button, styles.left)}
              variant="rounded"
              onClick={onClickLeft}
              color="white"
            >
              <Icon icon="leftArrow" />
            </Button>
            <Typography
              className={styles.text}
              variant="span"
              color="white"
              align="center"
            >
              {leftText}
            </Typography>
          </div>
        </div>
        <div className={clsx(styles.buttonWrapper, styles.center)}>
          <IconWithButton
            icon="submit"
            variant="rounded"
            buttonColor="green"
            color="white"
            text={centerText}
            onClick={onClickCenter}
          />
        </div>
        <div className={styles.buttonWrapper}>
          <div className={styles.buttonWithText}>
            <Button
              className={clsx(styles.button, styles.right)}
              variant="rounded"
              onClick={onClickRight}
              color="white"
            >
              <Icon icon="rightArrow" />
            </Button>
            <Typography
              className={styles.text}
              variant="span"
              color="white"
              align="center"
            >
              {rightText}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CameraConfirmFooter
