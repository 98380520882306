import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'

export type Variant = 'contained' | 'text' | 'rounded'
export type Color = 'primary' | 'white' | 'green' | 'gray' | 'blue'

type ButtonProps = {
  variant?: Variant
  type?: 'submit' | 'reset' | 'button'
  color?: Color
  onClick?: () => void
  disabled?: boolean
  bold?: boolean
}

type Props = ButtonProps & React.StyledProps & React.ChildrenProps

const Button: React.StyledFC<Props> = ({
  variant = 'contained',
  color = 'primary',
  bold = false,
  type = 'button',
  onClick,
  disabled = false,
  children,
  style,
  className,
}: Props) => {
  return (
    <button
      data-testid="button"
      type={type}
      style={style}
      className={clsx(
        styles.btn,
        styles[variant],
        styles[color],
        bold && styles.bold,
        className
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button
