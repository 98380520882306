import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { disableLeaveConfirmation, enableLeaveConfirmation } from '../../utils'
import { ApiRequest, AppError } from '../../AppTypes'
import { bobaService } from '../services'
import { ConsistencyResult, ConsistencyResultEnum } from '../services/api'
import { AuthorizationError, OutOfServiceError } from '../services/errors'
import {
  authenticationErrorAppError,
  internalServerErrorAppError,
  outOfServiceAppErr,
} from './common'
import { AppThunkConfig } from '../../store'

type State = { count: number } & ConsistencyResult & ApiRequest

const service = bobaService()

const initialState: State = {
  apiStatus: 'Initial',
  result: ConsistencyResultEnum.InProgress,
  count: 0,
  error: undefined,
}

const handleError = (err: Error): AppError => {
  if (err instanceof OutOfServiceError) {
    return outOfServiceAppErr
  }
  if (err instanceof AuthorizationError) {
    return authenticationErrorAppError
  }
  return internalServerErrorAppError
}

export const getConsistencyResultStatus = createAsyncThunk<
  ConsistencyResult,
  void,
  AppThunkConfig
>(
  'consistencyResultStatus/get',
  async (_args, { getState, rejectWithValue }) => {
    try {
      return await service.getConsistencyResults(getState())
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)
const consistencyResultStatusSlice = createSlice({
  name: 'consistencyResult',
  initialState,
  reducers: {
    clearConsistencyResult: (state: State): State => {
      return {
        ...initialState,
        count: state.count, // countはデバッグで使ってるので残す
      }
    },
    resetConsistencyResult: (state: State): State => {
      return {
        ...state,
        result: ConsistencyResultEnum.InProgress,
        error: undefined,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConsistencyResultStatus.pending, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(
      getConsistencyResultStatus.fulfilled,
      (state, { payload }) => {
        disableLeaveConfirmation()
        const { result } = payload
        return {
          ...state,
          ...payload,
          result,
          count:
            result !== ConsistencyResultEnum.InProgress
              ? state.count + 1
              : state.count,
          apiStatus: 'Success',
        }
      }
    )
    builder.addCase(getConsistencyResultStatus.rejected, (state, action) => {
      disableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Failure',
        error: action.payload,
      }
    })
  },
})

export const {
  resetConsistencyResult,
  clearConsistencyResult,
} = consistencyResultStatusSlice.actions

export default consistencyResultStatusSlice
