import React from 'react'
import clsx from 'clsx'
import Indicator from '../../atoms/Indicator'
import styles from './style.module.scss'
import Typography from '../../atoms/Typography'
import BgWrapper from '../../atoms/BgWrapper'

type ModalIndicatorProps = {
  text?: string
}

type Props = ModalIndicatorProps & React.StyledProps
const ModalIndicator: React.StyledFC<Props> = ({
  text,
  style,
  className,
}: Props) => {
  return (
    <BgWrapper
      color="white"
      variant="rounded"
      data-testid="modal-indicator"
      className={clsx(className, styles.container)}
      style={style}
    >
      {text && (
        <Typography
          variant="middleTitle"
          align="center"
          className={styles.text}
        >
          {text}
        </Typography>
      )}
      <Indicator size="small" className={styles.indicator} />
    </BgWrapper>
  )
}

export default ModalIndicator
