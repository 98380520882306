import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Image } from '../services/api'

type State = {
  images: Image[]
}

const initialState: State = {
  images: [],
}
const cameraSlice = createSlice({
  name: 'camera',
  initialState,
  reducers: {
    clearImage: (): State => {
      return initialState
    },
    takePhoto: (state, { payload }: PayloadAction<Image>): State => {
      const { index } = payload
      const currentImages = [...state.images]
      const idx = currentImages.findIndex((v) => v.index === index)
      if (idx >= 0) {
        currentImages[idx] = payload
      } else {
        currentImages.push(payload)
      }
      return {
        images: [
          ...currentImages
            .sort((a, b) => a.index - b.index)
            .map((image, i) => ({ index: i, data: image.data })),
        ],
      }
    },
    deleteImage: (state, { payload }: PayloadAction<number>): State => {
      const index = payload
      let currentImages = [...state.images]
      const idx = currentImages.findIndex((v) => v.index === index)
      if (idx >= 0) {
        currentImages.splice(idx, 1)
        currentImages = currentImages
          .sort((a, b) => a.index - b.index)
          .map((image, i) => ({ index: i, data: image.data }))
      }
      return { images: currentImages }
    },
  },
})

export const { takePhoto, clearImage, deleteImage } = cameraSlice.actions
export default cameraSlice
