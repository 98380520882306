import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'

const defaultVariantMapping = {
  largeTitle: 'h2',
  middleTitle: 'h4',
  smallTitle: 'span',
  description: 'p',
  note: 'p',
  caption: 'span',
  span: 'span',
}
type c = keyof JSX.IntrinsicElements
type TypographyProps = {
  variant?:
    | 'largeTitle'
    | 'middleTitle'
    | 'smallTitle'
    | 'note'
    | 'description'
    | 'caption'
    | 'span'
  bold?: boolean
  align?: 'left' | 'center'
  color?: AppColor
  component?: c
  newLine?: boolean
}

type Props = TypographyProps & React.StyledProps & React.ChildrenProps

const Typography: React.StyledFC<TypographyProps> = ({
  variant = 'span',
  component = undefined,
  color = 'primary',
  bold = false,
  align = 'left',
  newLine = false,
  style,
  className,
  children,
}: Props) => {
  const Tag: c = component || (defaultVariantMapping[variant] as c)
  return (
    <Tag
      className={clsx(
        className,
        styles.typography,
        styles[variant],
        bold && styles.bold,
        styles[align],
        styles[color],
        newLine && styles.newline
      )}
      style={style}
    >
      {children}
    </Tag>
  )
}

export default Typography
