import React, { ReactElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LargeTitleDescription from '../../components/molecules/LargeTitleDescription'
import TenantHeaderBottomButtonFullHeight from '../../components/templates/TenantHeaderBottomButtonFullHeight'
import useTenant from '../hooks/useTenant'
import styles from './style.module.scss'
import { title, description, buttonText, secondButtonText } from './message'
import {
  inputMessagePath,
  submittedPath,
  topWithClearDataPath,
} from '../../routes/paths'
import { RootState } from '../../store'
import ErrorModal from '../../components/organisms/ErrorModal'
import { AppError } from '../../AppTypes'
import LoadingModal from '../../components/organisms/LoadingModal'
import useDate from '../hooks/useDate'
import useMode from '../hooks/useMode'
import { clearSubmitStatus } from '../../features/network/submitSlice'
import useAppHistory from '../../utils/useAppHistory'

function MessageConfirmation(): ReactElement {
  const tenant = useTenant()
  const mode = useMode()
  const history = useAppHistory()
  const dispatch = useDispatch()
  const { error, apiStatus } = useSelector((state: RootState) => state.submit)

  const handleClickFinish = (): void => {
    history.push(submittedPath)
  }

  const handleClickNext = (): void => {
    history.push(inputMessagePath)
  }

  const handleErrorModalButtonClicked = (appError: AppError): void => {
    if (appError.needReturnTop) {
      history.push(topWithClearDataPath)
    } else {
      dispatch(clearSubmitStatus())
    }
  }

  return (
    <TenantHeaderBottomButtonFullHeight
      className={styles.container}
      buttonText={buttonText}
      buttonColor="green"
      secondButtonText={secondButtonText}
      secondButtonColor="primary"
      secondButtonVariant="text"
      mode={mode}
      date={useDate()}
      tenant={tenant}
      onClick={handleClickFinish}
      onClickSecond={handleClickNext}
      currentStep={process.env.REACT_APP_OIOI_MODULE ? 3 : 4}
    >
      {apiStatus === 'Progress' && (
        <LoadingModal isOpen className={styles.loadingModal} />
      )}
      {error && (
        <ErrorModal
          isOpen={Boolean(error)}
          title={error.title}
          description={error.description}
          buttonTitle="OK"
          onClick={(): void => handleErrorModalButtonClicked(error)}
        />
      )}
      <LargeTitleDescription
        className={styles.txt}
        title={title}
        description={description}
        color="primary"
        icon="memo"
      />
    </TenantHeaderBottomButtonFullHeight>
  )
}

export default MessageConfirmation
