/* eslint-disable no-restricted-syntax */
import React from 'react'
import clsx from 'clsx'
import DecoratedInputForm from '../../molecules/DecoratedInputForm'
import styles from './style.module.scss'
import { Form } from '../../../features/services/api'

type FormWithValue = Form & {
  answer?: string
  errorMessage?: string
}

type SalesDataFormProps = {
  forms: FormWithValue[]
  onChange?: (name: string, value: string) => void
}

type Props = SalesDataFormProps & React.StyledProps
const SalesDataForm: React.StyledFC<Props> = ({
  forms,
  onChange,
  style,
  className,
}: Props) => {
  const handleEnter = (index: number): void => {
    const nextIndex = index + 1

    const inputForms = document.querySelectorAll<HTMLElement>(
      '[data-testid="input-form"]'
    )
    if (inputForms.length === nextIndex) return

    if (inputForms[nextIndex]) {
      inputForms[nextIndex].focus()
    }
  }

  return (
    <div className={clsx(className, styles.form)} style={style}>
      <div>
        {forms.map((f, i) => {
          return (
            <DecoratedInputForm
              className={styles.item}
              key={f.id}
              onChange={onChange}
              onEnter={(): void => handleEnter(i)}
              suffix={f.suffix}
              variant={f.type}
              defaultValue={f.answer}
              label={f.label}
              id={f.id}
              name={f.id}
              errorMessage={f.errorMessage}
            />
          )
        })}
      </div>
    </div>
  )
}

export default SalesDataForm
