import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { ModeEnum } from '../../features/mode/modeSlice'

const useMode = (): ModeEnum => {
  const { mode } = useSelector((state: RootState) => state.mode)
  return mode
}

export const useNormalMode = (): boolean => {
  const mode = useMode()
  return mode === ModeEnum.Normal
}

export const useTrainingMode = (): boolean => {
  const mode = useMode()
  return mode === ModeEnum.Training
}

export const useResentMode = (): boolean => {
  const mode = useMode()
  return mode === ModeEnum.Resent
}

export default useMode
