import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { bugsnagApiKey } from '../../constants'

type Props = React.ChildrenProps
const DefaultBugsnag: React.StyledFC<Props> = ({ children }: Props) => {
  return <div>{children}</div>
}

const ErrorBoundary = ((): React.ComponentType => {
  if (!bugsnagApiKey) {
    return DefaultBugsnag
  }
  Bugsnag.start({
    releaseStage: process.env.REACT_APP_ENV,
    apiKey: bugsnagApiKey,
    plugins: [new BugsnagPluginReact()],
  })
  const plugin = Bugsnag.getPlugin('react')
  return plugin ? plugin.createErrorBoundary(React) : DefaultBugsnag
})()

export default ErrorBoundary
