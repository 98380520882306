import React, { ReactElement, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AppHeader from '../../components/organisms/AppHeader'
import { RootState } from '../../store'
import styles from './style.module.scss'
import LoadingModal from '../../components/organisms/LoadingModal'
import Typography from '../../components/atoms/Typography'
import {
  titleText,
  submitBtnText,
  irregularTitleText,
  diffDescription,
  lengthError,
} from './messages'
import SalesDataForm from '../../components/organisms/SalesDataForm'
import {
  updateAnswer,
  getFormsSlice,
  clearForms,
} from '../../features/network/formsSlice'
import {
  inputSalesDataConfirmPath,
  topWithClearDataPath,
} from '../../routes/paths'
import useTenant from '../hooks/useTenant'
import useExpire from '../hooks/useExpire'
import { Form } from '../../features/services/api'
import { AppError } from '../../AppTypes'
import ErrorModal from '../../components/organisms/ErrorModal'
import useDate from '../hooks/useDate'
import FullSize from '../../components/templates/FullSize'
import useMode from '../hooks/useMode'
import StepperHeader from '../../components/organisms/StepperHeader'
import {
  clearConsistencyStatements,
  getConsistencyStatements,
} from '../../features/network/consistencySlice'
import SalesDataFormFooter from '../../components/molecules/SalesDataFormFooter'
import usePrecheck from '../hooks/usePrecheck'
import SalesDataResults from '../../components/molecules/SalesDataResults'
import useValidate, { ValidateStep } from '../../utils/useValidate'
import useAppHistory from '../../utils/useAppHistory'

type FormWithAnswer = Form & { answer: string; errorMessage?: string }

const MAX_LENGTH = 9

const errorMessage = (val: string): string | undefined => {
  if (val.length > MAX_LENGTH) {
    return lengthError(MAX_LENGTH)
  }
  return undefined
}

function InputSalesData(): ReactElement {
  useExpire()
  const dispatch = useDispatch()
  const tenant = useTenant()
  const { forms, apiStatus, answers, error } = useSelector(
    (state: RootState) => state.inputData
  )
  const { registerCount } = useSelector((state: RootState) => state.register)
  const mode = useMode()
  useEffect(() => {
    if (!forms.length) {
      dispatch(getFormsSlice())
    }
  }, [forms, dispatch])

  const combineFormAnswer = useMemo<FormWithAnswer[]>(() => {
    return forms.map((f) => {
      const ans = answers.find((a) => a.formId === f.id)
      return {
        ...f,
        answer: ans?.value || '',
        errorMessage: errorMessage(ans?.value || ''),
      }
    })
  }, [answers, forms])

  const {
    apiStatus: consistencyApiStatus,
    error: consistencyError,
  } = useSelector((state: RootState) => state.consistency)
  useEffect(() => {
    if (consistencyApiStatus === 'Initial') {
      dispatch(getConsistencyStatements())
    }
  }, [consistencyApiStatus])

  const history = useAppHistory()
  const handleChange = (name: string, value: string): void => {
    dispatch(updateAnswer({ formId: name, value }))
  }
  const handleSubmit = (): void => {
    history.push(inputSalesDataConfirmPath)
  }
  const handleError = (err: AppError): void => {
    if (err.needReturnTop) {
      history.push(topWithClearDataPath)
      return
    }
    dispatch(clearForms())
    dispatch(clearConsistencyStatements())
  }

  const { statements } = useSelector((state: RootState) => state.consistency)
  const precheckResults = useMemo(() => {
    if (apiStatus === 'Success' && consistencyApiStatus === 'Success') {
      return usePrecheck(statements, answers)
    }
    return []
  }, [apiStatus, consistencyApiStatus, answers])

  const showDescription = useMemo<boolean>(() => {
    return precheckResults.some((r) => r.value !== 0)
  }, [precheckResults])

  const enabledSubmitButton = useMemo(() => {
    return (
      apiStatus !== 'Progress' &&
      answers.length &&
      answers.every((a) => !a.required || a.value) &&
      combineFormAnswer.every((e) => !e.errorMessage) &&
      precheckResults.every((r) => r.value === 0)
    )
  }, [apiStatus, consistencyApiStatus, answers, precheckResults])

  const validate = useValidate()
  useEffect(() => {
    validate(ValidateStep.InputSalesData)
  }, [])

  return (
    <FullSize mode={mode} date={useDate()}>
      <div className={styles.container}>
        {apiStatus === 'Progress' && consistencyApiStatus === 'Progress' && (
          <LoadingModal isOpen className={styles.loadingModal} />
        )}
        <div>
          <StepperHeader className={styles.stepper} current={2} />
          <AppHeader
            tenantName={tenant.tenantName}
            storeName={tenant.storeName}
          />
          <Typography
            className={styles.title}
            variant="largeTitle"
            align="left"
            color="primary"
          >
            {tenant.ocrEnabled ? titleText(registerCount) : irregularTitleText}
          </Typography>
        </div>
        {forms && (
          <SalesDataForm
            className={styles.forms}
            forms={combineFormAnswer}
            onChange={handleChange}
          />
        )}
        {precheckResults.length > 0 && (
          <SalesDataResults
            className={styles.results}
            results={precheckResults}
          />
        )}
        <SalesDataFormFooter
          className={styles.footer}
          buttonText={submitBtnText}
          results={precheckResults}
          onClick={handleSubmit}
          disabled={!enabledSubmitButton}
          description={diffDescription}
          showDescription={showDescription}
        />
        {error && (
          <ErrorModal
            isOpen
            title={error.title}
            description={error.description}
            buttonTitle="OK"
            onClick={(): void => handleError(error)}
          />
        )}
        {consistencyError && (
          <ErrorModal
            isOpen
            title={consistencyError.title}
            description={consistencyError.description}
            buttonTitle="OK"
            onClick={(): void => handleError(consistencyError)}
          />
        )}
      </div>
    </FullSize>
  )
}

export default InputSalesData
