import React from 'react'
import clsx from 'clsx'
import Typography from '../../atoms/Typography'
import styles from './style.module.scss'

type LabelValueProps = {
  label: string
  value: string
}

type Props = LabelValueProps & React.StyledProps
const LabelValue: React.StyledFC<Props> = ({
  label,
  value,
  style,
  className,
}: Props) => {
  return (
    <div className={clsx(className, styles.container)} style={style}>
      <Typography variant="smallTitle" color="primary" align="left">
        {label}
      </Typography>
      <Typography variant="span" className={styles.value} color="primary">
        {value}
      </Typography>
    </div>
  )
}

export default LabelValue
