import React from 'react'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import styles from './style.module.scss'
import { ReactComponent as IndicatorSvg } from './assets/indicator.svg'

type IndicatorProps = {
  size?: 'small' | 'normal' | 'large'
  color?: 'green'
}

type Props = IndicatorProps & React.StyledProps
const Indicator: React.StyledFC<Props> = ({
  size = 'small',
  color = 'green',
  style,
  className,
}: Props) => {
  return (
    <motion.div
      animate={{ rotate: 360 }}
      data-testid="indicator"
      transition={{
        duration: 1.6,
        loop: Infinity,
        type: 'tween',
        ease: 'linear',
      }}
      className={clsx(styles.indicator, styles[size], styles[color], className)}
      style={style}
    >
      <IndicatorSvg />
    </motion.div>
  )
}

export default Indicator
