// eslint-disable-next-line import/prefer-default-export
export const sleep = (msec: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, msec))

export const enableLeaveConfirmation = (): void => {
  window.onbeforeunload = (): string => {
    return 'Are you sure you want to leave?'
  }
}

export const disableLeaveConfirmation = (): void => {
  window.onbeforeunload = (): null => {
    return null
  }
}

export const getWindowHeight = (): number => {
  return (
    (document &&
      document.documentElement &&
      document.documentElement.clientHeight) ||
    window.innerHeight
  )
}

export const getWindowWidth = (): number => {
  return (
    (document &&
      document.documentElement &&
      document.documentElement.clientWidth) ||
    window.innerWidth
  )
}
