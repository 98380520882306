import React, { useState } from 'react'
import clsx from 'clsx'
import dayjs from 'dayjs'
import styles from './style.module.scss'

export type DateInputErrorCode = 'over_max'

export type DateInputFormProps = {
  name: string
  max?: Date
  min?: Date
  defaultValue?: Date
  format?: DateFormat
  required?: boolean
  autoComplete?: 'on' | 'off'
  autocorrect?: 'on' | 'off'
  onChange?: (date: Date) => void
  onError?: (err: DateInputErrorCode) => void
}

const formDefaultFormat = 'YYYY-MM-DD'
const formFormatter = (
  date: Date | undefined,
  format: string = formDefaultFormat
): string | undefined => {
  if (!date) {
    return undefined
  }
  return dayjs(date).format(format)
}

type Props = DateInputFormProps & React.StyledProps
const DateInputForm: React.StyledFC<Props> = ({
  name,
  max,
  min,
  defaultValue,
  required = false,
  autoComplete = 'off',
  onChange,
  onError,
  style,
  className,
}: Props) => {
  const [v, setValue] = useState<Date | undefined>(defaultValue)
  const handleChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = event.target.value
    const maxDate = max && dayjs(max)
    let inputDate = dayjs(inputValue)
    if (maxDate && inputDate.isAfter(maxDate)) {
      inputDate = maxDate
      if (onError) {
        onError('over_max')
      }
    }
    const d = inputDate.toDate()
    setValue(d)
    if (onChange) {
      onChange(d)
    }
  }
  return (
    <input
      type="date"
      name={name}
      data-testid="date-input-form"
      max={formFormatter(max)}
      min={formFormatter(min)}
      value={formFormatter(v)}
      required={required}
      autoComplete={autoComplete}
      style={style}
      onChange={handleChanged}
      className={clsx(className, styles.input)}
    />
  )
}

export default DateInputForm
