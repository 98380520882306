import clsx from 'clsx'
import React from 'react'
import Button, { Color, Variant } from '../../atoms/Button'
import Icon, { IconNames } from '../../atoms/Icon'
import Typography from '../../atoms/Typography'
import styles from './style.module.scss'

type IconWithButtonProps = {
  icon: IconNames
  color: AppColor
  variant: Variant
  buttonColor: Color
  text: string
  onClick?: () => void
}

type Props = IconWithButtonProps & React.StyledProps & React.ChildrenProps

const IconWithButton: React.StyledFC<Props> = ({
  icon,
  color,
  variant,
  buttonColor,
  text,
  onClick,
  style,
  className,
}: Props) => {
  return (
    <Button
      variant={variant}
      color={buttonColor}
      className={clsx(styles.button, className)}
      style={style}
      onClick={onClick}
    >
      <div className={styles.wrapper}>
        <Icon icon={icon} color={color} className={styles.icon} />
        <Typography className={styles.text} variant="note" color={color} bold>
          {text}
        </Typography>
      </div>
    </Button>
  )
}

export default IconWithButton
