import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { bobaService } from '../services'

import { AppThunkConfig } from '../../store'
import { ApiRequest, AppError } from '../../AppTypes'
import {
  ApiError,
  AuthorizationError,
  OutOfServiceError,
} from '../services/errors'
import { ErrorCodeEnum } from '../services/api'
import {
  authenticationErrorAppError,
  internalServerErrorAppError,
  outOfServiceAppErr,
} from './common'
import { disableLeaveConfirmation, enableLeaveConfirmation } from '../../utils'

const service = bobaService()

type State = ApiRequest

const initialState: ApiRequest = {
  apiStatus: 'Initial',
  error: undefined,
}

const handleError = (err: Error): AppError | null => {
  if (err instanceof ApiError) {
    switch (err.errorCode) {
      case ErrorCodeEnum.InvalidInput:
        return {
          needReturnTop: false,
          description:
            '売上登録に不正な入力があります。値がちゃんと入力されているか、数字以外の入力がないかご確認ください。',
          title: '売上登録エラー',
        }
      case ErrorCodeEnum.NotFound:
        return {
          needReturnTop: true,
          description:
            '複数端末での利用が確認されましたので、当端末での売上報告は無効となりました。',
          title: '売上報告無効',
        }
      case ErrorCodeEnum.InvalidToken:
        return {
          needReturnTop: true,
          description:
            '所定の時間が経過したので、無効になりました。初めからお試しください。',
          title: '',
        }
      case ErrorCodeEnum.AlreadySubmitted:
        return null
      case ErrorCodeEnum.SubmitValidate:
        return {
          needReturnTop: false,
          description:
            '売上データを登録中にエラーが発生しました。時間をおいて再度お試しください。',
          title: '',
        }
      case ErrorCodeEnum.InternalServerError:
        return internalServerErrorAppError
      default:
        return {
          needReturnTop: false,
          description:
            '売上データを登録中にエラーが発生しました。時間をおいて再度お試しください。',
          title: '売上登録エラー',
        }
    }
  } else if (err instanceof OutOfServiceError) {
    return outOfServiceAppErr
  } else if (err instanceof AuthorizationError) {
    return authenticationErrorAppError
  }
  return internalServerErrorAppError
}

export const postSubmit = createAsyncThunk<void, void, AppThunkConfig>(
  'submit/create',
  async (_, { getState, rejectWithValue }) => {
    try {
      return await service.postSubmit(getState())
    } catch (err) {
      const handleResult = handleError(err)
      if (handleResult !== null) {
        return rejectWithValue(handleResult)
      }
      // NOTE: ErrorCodeEnum.AlreadySubmittedのときには正常終了とする
      return Promise.resolve()
    }
  }
)
const submitSlice = createSlice({
  name: 'submit',
  initialState,
  reducers: {
    clearSubmitStatus: (): State => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postSubmit.pending, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(postSubmit.fulfilled, (state) => {
      disableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Success',
      }
    })
    builder.addCase(postSubmit.rejected, (state, action) => {
      disableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Failure',
        error: action.payload,
      }
    })
  },
})

export const { clearSubmitStatus } = submitSlice.actions

export default submitSlice
