import React, { ReactElement } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../store'

type AuthRouterProps = {
  redirectPath?: string
}

type Props = AuthRouterProps & RouteProps & React.ChildrenProps
const NeedAuth = ({
  redirectPath = '/',
  children,
  ...rest
}: Props): ReactElement => {
  const { tenant } = useSelector((state: RootState) => state.auth)
  return (
    <Route
      {...rest}
      render={({ location }): React.ReactNode =>
        tenant && tenant.id ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default NeedAuth
