import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'
import Icon from '../../atoms/Icon'
import Typography from '../../atoms/Typography'
import Space from '../../atoms/Space'

type AddRectangleProps = {
  onClick?: () => void
}

type Props = AddRectangleProps & React.StyledProps
const AddRectangle: React.StyledFC<Props> = ({
  onClick,
  style,
  className,
}: Props) => {
  return (
    <div
      className={clsx(className, styles.container)}
      style={style}
      onClick={onClick}
    >
      <Icon icon="plus" color="primary" className={styles.icon} />
      <Space orientation="horizontal" size="8px" />
      <Typography variant="note" color="primary" align="center">
        写真を追加する
      </Typography>
    </div>
  )
}

export default AddRectangle
