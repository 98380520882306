import React from 'react'
import clsx from 'clsx'
import Button from '../../atoms/Button'
import styles from './style.module.scss'
import Typography from '../../atoms/Typography'

export type Result = {
  title: string
  value: number
  unit: string
}

export type SalesDataFormFooterProps = {
  buttonText: string
  disabled?: boolean
  results: Result[]
  description: string
  showDescription?: boolean
  onClick: () => void
}

type Props = SalesDataFormFooterProps & React.StyledProps & React.ChildrenProps

const SalesDataFormFooter: React.StyledFC<Props> = ({
  buttonText,
  disabled,
  results,
  description,
  showDescription,
  onClick,
  className,
}: Props) => {
  return (
    <div className={clsx(styles.wrapper, className)}>
      <div
        className={clsx(styles.footer, results.length === 0 && styles.radius)}
      >
        <Button
          className={styles.button}
          variant="contained"
          color="green"
          onClick={onClick}
          disabled={disabled}
        >
          {buttonText}
        </Button>
        {results.length > 0 && (
          <Typography
            color="white"
            className={clsx(
              styles.description,
              !showDescription && styles.hidden
            )}
          >
            {description}
          </Typography>
        )}
      </div>
    </div>
  )
}

export default SalesDataFormFooter
