import React, { ReactElement, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ModeEnum } from '../features/mode/modeSlice'
import { RootState } from '../store'

type TitleRouterProps = {
  title: string
  Component: React.ComponentType
}

const baseTitle = '売上報告アプリ'
const trainingModeTitle = '練習モード'
const reportModeTitle = '本番報告'

type Props = TitleRouterProps & unknown
const WithTitle = ({ title, Component, ...p }: Props): ReactElement => {
  const location = useLocation()
  const mode = useSelector((state: RootState) => state.mode.mode)
  const modeTitle =
    mode === ModeEnum.Training ? trainingModeTitle : reportModeTitle

  useEffect(() => {
    document.title = `${baseTitle} | ${modeTitle} | ${title}`
  }, [location, modeTitle, title])
  return (
    <>
      <Component {...p} />
    </>
  )
}

export default WithTitle
