import { useSelector } from 'react-redux'
import Bugsnag from '@bugsnag/js'
import { RootState } from '../store'
import useTenant from '../pages/hooks/useTenant'
import { formatDate } from '../pages/hooks/useDate'
import useMode from '../pages/hooks/useMode'

export enum ValidateStep {
  InputSalesData = 'inputSalesData',
  RegisterConfirmation = 'registerConfirmation',
  Submitted = 'submitted',
}

const useValidate = (): ((step: ValidateStep) => boolean) => {
  const tenant = useTenant()
  const salesDate = formatDate()
  const mode = useMode()
  const apiStatuses = {
    uploadImage: useSelector((state: RootState) => state.uploadImage).apiStatus,
    inputData: useSelector((state: RootState) => state.inputData).apiStatus,
    submit: useSelector((state: RootState) => state.submit).apiStatus,
  }

  // 通常報告の時のみValidationを実行
  const isNormal = mode === 'normal'
  // 画像アップロードのボタンを押したか？
  const isUploaded =
    apiStatuses.uploadImage === 'Success' ||
    apiStatuses.uploadImage === 'Progress'
  // 画像アップロード、手入力のボタンを押したか？
  const isInputData =
    (apiStatuses.inputData === 'Success' ||
      apiStatuses.inputData === 'Progress') &&
    isUploaded
  // 画像アップロード、手入力、送信のボタンを押したか？
  const isSubmitted =
    (apiStatuses.submit === 'Success' || apiStatuses.submit === 'Progress') &&
    isInputData

  return (step): boolean => {
    const failedSteps: string[] = []

    const stepValidations = {
      [ValidateStep.InputSalesData]: (): void => {
        if (!isUploaded && isNormal) {
          failedSteps.push('failed image upload')
        }
      },
      [ValidateStep.RegisterConfirmation]: (): void => {
        stepValidations[ValidateStep.InputSalesData]()
        if (!isInputData && isNormal) {
          failedSteps.push('failed post input data')
        }
      },
      [ValidateStep.Submitted]: (): void => {
        stepValidations[ValidateStep.RegisterConfirmation]()
        if (!isSubmitted && isNormal) {
          failedSteps.push('failed submitted')
        }
      },
    }

    if (stepValidations[step]) {
      stepValidations[step]()
    }

    if (failedSteps.length > 0) {
      Bugsnag.notify({
        name: 'Validation Error',
        message: `Validation Error: tenant: ${
          tenant.id
        }, sales_date: ${salesDate}, ${failedSteps.join(', ')}, `,
      })
    }

    return failedSteps.length === 0
  }
}

export default useValidate
