import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'
import Typography from '../../atoms/Typography'

export type Result = {
  title: string
  value: number
  unit: string
}

type SalesDataResultsProps = {
  results: Result[]
}

type Props = SalesDataResultsProps & React.StyledProps & React.ChildrenProps

const SalesDataResults: React.StyledFC<Props> = ({
  results,
  className,
}: Props) => {
  return (
    <div className={clsx(styles.wrapper, className)}>
      {results.length && (
        <div className={styles.results}>
          {results.map((r) => {
            const textColor = r.value === 0 ? 'white' : 'error'
            return (
              <div className={styles.item} key={r.title}>
                <Typography color="white">{r.title}</Typography>
                <div>
                  <Typography color={textColor}>
                    {r.value.toLocaleString()}
                  </Typography>
                  <Typography color={textColor} className={styles.unit}>
                    {r.unit}
                  </Typography>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default SalesDataResults
