import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'
import Space from '../../atoms/Space'

type TenantShopNameProps = {
  shopName: string
  tenantName: string
}

type Props = TenantShopNameProps & React.StyledProps
const TenantShopName: React.StyledFC<Props> = ({
  shopName,
  tenantName,
  style,
  className,
}: Props) => {
  return (
    <div className={clsx(className, styles.container)} style={style}>
      <span className={styles.shopName}>{shopName}</span>
      <Space orientation="vertical" size="4px" />
      <span className={styles.tenantName}>{tenantName}</span>
    </div>
  )
}

export default TenantShopName
