// eslint-disable-next-line import/prefer-default-export
export const titleText = `最初に登録する売上日を設定しましょう`
export const resentTitleText = '再報告する売上日を確認しましょう'

export const prevText = '戻る'
export const nextText = '次へ'

export const loadingModalText = `通信状態によって
約30秒ほど時間がかかる場合がございます。
ご了承ください。`
