import { Dispatch, useEffect, useState } from 'react'

export default (): [
  string | null,
  Dispatch<string | null>,
  Dispatch<number>
] => {
  const [base64Image, setBase64Image] = useState<string | null>(null)
  const [currentCount, setCurrentCount] = useState<number>(-1)
  useEffect(() => {
    setBase64Image(null)
  }, [currentCount])
  return [base64Image, setBase64Image, setCurrentCount]
}
