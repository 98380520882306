// eslint-disable-next-line max-classes-per-file
import { ErrorCodeEnum } from './api'

export class AuthorizationError extends Error {}

export class OutOfServiceError extends Error {
  readonly nextActiveTime?: Date

  constructor(message: string, nextActiveTime?: Date) {
    super(message)
    this.nextActiveTime = nextActiveTime
  }
}

export class ApiError extends Error {
  readonly statusCode: number

  readonly errorCode: ErrorCodeEnum | null

  constructor(
    message: string,
    statusCode: number,
    errorCode: ErrorCodeEnum | null
  ) {
    super(message)
    this.statusCode = statusCode
    this.errorCode = errorCode
  }
}
