import React from 'react'
import clsx from 'clsx'
import AppHeader from '../../organisms/AppHeader'
import Button from '../../atoms/Button'
import styles from './style.module.scss'
import { Tenant } from '../../../features/services/api'
import Div100vh from '../../atoms/Div100vh'
import Space from '../../atoms/Space'
import { ModeEnum } from '../../../features/mode/modeSlice'
import StepperHeader from '../../organisms/StepperHeader'
import Typography from '../../atoms/Typography'
import Icon from '../../atoms/Icon'

type TenantHeaderBottomButtonFullHeightProps = {
  tenant: Tenant | null
  buttonText?: string
  secondButtonText?: string
  buttonColor?: Color
  secondButtonColor?: Color
  disabledButton?: boolean
  buttonVariant?: ButtonVariant
  secondButtonVariant?: ButtonVariant
  alertMessage?: string
  mode?: ModeEnum
  date?: Date
  currentStep?: number
  onClick?: () => void
  onClickSecond?: () => void
}

type ButtonVariant = 'contained' | 'text' | 'rounded'

type Color = 'primary' | 'white' | 'green'

type Props = TenantHeaderBottomButtonFullHeightProps &
  React.StyledProps &
  React.ChildrenProps
const TenantHeaderBottomButtonFullHeight: React.StyledFC<Props> = ({
  tenant,
  buttonText,
  onClick,
  buttonColor = 'primary',
  buttonVariant = 'contained',
  disabledButton,
  secondButtonText,
  secondButtonColor = 'primary',
  secondButtonVariant = 'contained',
  alertMessage,
  mode = ModeEnum.Normal,
  date,
  currentStep,
  onClickSecond,
  style,
  className,
  children,
}: Props) => {
  return (
    <Div100vh
      className={clsx(styles.container, className)}
      mode={mode}
      date={date}
      style={style}
    >
      {currentStep !== undefined && <StepperHeader current={currentStep} />}
      {tenant ? (
        <AppHeader
          tenantName={tenant.tenantName}
          storeName={tenant.storeName}
        />
      ) : (
        <div className={styles.fakeHeader} />
      )}
      <div className={styles.wrapper}>
        <div>{children}</div>
        {alertMessage && (
          <div className={styles.alertWrapper}>
            <Icon className={styles.icon} icon="alert" />
            <Typography className={styles.alert} color="error">
              {alertMessage}
            </Typography>
          </div>
        )}
        <div className={styles.buttonWrapper}>
          {buttonText && (
            <Button
              color={buttonColor}
              variant={buttonVariant}
              onClick={onClick}
              className={clsx(
                styles.button,
                buttonVariant === 'text' && styles.text
              )}
              disabled={disabledButton}
            >
              {buttonText}
            </Button>
          )}
          {secondButtonText && (
            <>
              <Space size="18px" orientation="vertical" />
              <Button
                color={secondButtonColor}
                variant={secondButtonVariant}
                onClick={onClickSecond}
                className={clsx(
                  styles.button,
                  secondButtonVariant === 'text' && styles.text
                )}
              >
                {secondButtonText}
              </Button>
            </>
          )}
        </div>
      </div>
    </Div100vh>
  )
}

export default TenantHeaderBottomButtonFullHeight
