import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../components/atoms/Button'
import useTenant from '../hooks/useTenant'
import styles from './style.module.scss'
import { title, fixButtonText, buttonText } from './message'
import {
  inputMessagePath,
  submittedPath,
  topWithClearDataPath,
} from '../../routes/paths'
import { RootState } from '../../store'
import ErrorModal from '../../components/organisms/ErrorModal'
import { AppError } from '../../AppTypes'
import LoadingModal from '../../components/organisms/LoadingModal'
import Typography from '../../components/atoms/Typography'
import {
  clearApiRequest,
  putMessage,
  PutMessageParam,
} from '../../features/network/messageSlice'
import Div100vh from '../../components/atoms/Div100vh'
import AppHeader from '../../components/organisms/AppHeader'
import Space from '../../components/atoms/Space'
import useMode, { useTrainingMode } from '../hooks/useMode'
import useDate from '../hooks/useDate'
import StepperHeader from '../../components/organisms/StepperHeader'
import useAppHistory from '../../utils/useAppHistory'

function InputMessageConfirm(): ReactElement {
  const [isProgress, setInProgress] = useState(false)

  const tenant = useTenant()
  const mode = useMode()
  const history = useAppHistory()
  const isTraining = useTrainingMode()

  const dispatch = useDispatch()
  const { body, error, apiStatus } = useSelector(
    (state: RootState) => state.message
  )
  const date = useDate()

  const handleButtonClick = async (): Promise<void> => {
    if (isTraining) {
      history.push(submittedPath)
      return
    }

    setInProgress(true)
    const param: PutMessageParam = {
      message: body,
    }
    const actionResult = await dispatch(putMessage(param))
    if (
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      putMessage.fulfilled.match(actionResult)
    ) {
      history.push(submittedPath)
    }
  }

  const handleFixButtonClick = (): void => {
    history.push(inputMessagePath)
  }

  const handleErrorClick = (err: AppError): void => {
    if (err.needReturnTop) {
      history.push(topWithClearDataPath)
      return
    }
    dispatch(clearApiRequest())
  }

  useEffect(() => {
    if (!error) return
    setInProgress(false)
  }, [error])

  return (
    <Div100vh className={styles.container} mode={mode} date={date}>
      <StepperHeader current={process.env.REACT_APP_OIOI_MODULE ? 3 : 4} />
      <AppHeader tenantName={tenant.tenantName} storeName={tenant.storeName} />
      <div className={styles.wrapper}>
        <Typography
          className={styles.title}
          variant="largeTitle"
          newLine
          align="left"
          color="primary"
          bold
        >
          {title}
        </Typography>
        <Space size="26px" orientation="vertical" />
        <div className={styles.content}>
          <div className={styles.contentValue}>
            <Typography>{body}</Typography>
          </div>
        </div>
        <Space orientation="vertical" size="42px" />
        <div className={styles.buttons}>
          <Button
            variant="contained"
            color="white"
            className={styles.button}
            onClick={handleFixButtonClick}
          >
            {fixButtonText}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={styles.button}
            onClick={handleButtonClick}
          >
            {buttonText}
          </Button>
        </div>
        <Space size="42px" orientation="vertical" />
      </div>
      {(apiStatus === 'Progress' || isProgress) && (
        <LoadingModal isOpen className={styles.loadingModal} />
      )}
      {error && (
        <ErrorModal
          isOpen
          title={error.title}
          description={error.description}
          buttonTitle="OK"
          onClick={(): void => handleErrorClick(error)}
        />
      )}
    </Div100vh>
  )
}

export default InputMessageConfirm
