import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { RootState } from '../../store'

const useDate = (): Date => {
  const { salesDateAsNumber } = useSelector(
    (state: RootState) => state.salesDate
  )
  return new Date(salesDateAsNumber)
}

export const formatDate = (): string => {
  const date = useDate()
  return dayjs(date).format('YYYY-MM-DD')
}

export default useDate
