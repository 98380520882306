import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import ReceiptVideo from '../../components/templates/ReceiptVideo'
import {
  cancel,
  centerText,
  firstDescription,
  horizontalButtonText,
  leftText,
  rightButtonText,
  rightText,
  rotateMessage,
  secondDescription,
  thirdDescription,
  verticalButtonText,
} from './message'
import { cameraConfirmPath, genCameraPath } from '../../routes/paths'
import { RootState } from '../../store'
import { getWindowHeight, getWindowWidth } from '../../utils'
import useCaptureImage from './hooks/useCaptureImage'
import useExpire from '../hooks/useExpire'
import Div100vh from '../../components/atoms/Div100vh'
import MessageModal from '../../components/organisms/MessageModal'
import CaptureConfirm from '../../components/templates/CaptureConfirm'
import { takePhoto } from '../../features/camera/cameraSlice'
import useRePromptVideo from '../../utils/useRePromptVideo'
import useAppHistory from '../../utils/useAppHistory'

interface ParamTypes {
  count: string
}

const VIDEO_ID = 'receipt-video'

function ReceiptCamera(): ReactElement {
  useExpire()
  const history = useAppHistory()

  // number of shots
  const { count } = useParams<ParamTypes>()
  const countAsInt = +count
  const dispatch = useDispatch()
  const { images } = useSelector((state: RootState) => state.camera)
  const [base64Image, setBase64Image, setCurrentCount] = useCaptureImage()
  useEffect((): void => {
    setCurrentCount(countAsInt)
  }, [countAsInt, setCurrentCount])
  if (!images.length && countAsInt) {
    history.push(genCameraPath(0))
  }
  const isFirstPhoto = countAsInt === 0
  let prevImg
  if (!isFirstPhoto && images.length >= countAsInt - 1) {
    if (images[countAsInt - 1]) {
      prevImg = images[countAsInt - 1].data
    }
  }

  // vertical or horizontal
  const [isVertical, setIsVertical] = useState(true)
  useEffect(() => {
    const handleResize = (): void => {
      setIsVertical(getWindowHeight() > getWindowWidth())
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return (): void => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // rotate modal
  const [isMessageOpen, setIsMessageOpen] = useState(false)
  const [message, setMessage] = useState('')
  useEffect(() => {
    setMessage(rotateMessage(isVertical))

    if (isMessageOpen) {
      setIsMessageOpen(false)
    }
  }, [isVertical])
  const openRotateModal = (): void => {
    setIsMessageOpen(true)
  }

  const handleClickClose = (): void => {
    history.push(cameraConfirmPath)
  }

  const handleCapture = (base64Encoded: string): void => {
    setBase64Image(base64Encoded)
  }

  const handleClickRecapture = (): void => {
    setBase64Image(null)
  }

  const handleClickNext = (): void => {
    if (base64Image) {
      dispatch(takePhoto({ index: countAsInt, data: base64Image }))
      history.push(genCameraPath(countAsInt + 1))
    }
  }

  const handleClickFinish = (): void => {
    if (base64Image) {
      dispatch(takePhoto({ index: countAsInt, data: base64Image }))
      history.push(cameraConfirmPath)
    }
  }

  return (
    <Div100vh disabledModeWrapper>
      <MessageModal
        isOpen={isMessageOpen}
        message={message}
        buttonTitle={cancel}
        onClick={(): void => setIsMessageOpen(false)}
      />
      {base64Image ? (
        <CaptureConfirm
          src={base64Image}
          full={!isFirstPhoto}
          centerText={centerText}
          rightText={rightText}
          leftText={leftText}
          onClickCenter={handleClickFinish}
          onClickRight={handleClickNext}
          onClickLeft={handleClickRecapture}
        />
      ) : (
        <ReceiptVideo
          videoId={VIDEO_ID}
          isVertical={isVertical}
          prevSrc={prevImg}
          firstDescription={firstDescription}
          secondDescription={secondDescription}
          thirdDescription={thirdDescription}
          leftButtonText={
            isVertical ? horizontalButtonText : verticalButtonText
          }
          rightButtonText={rightButtonText}
          onClickClose={handleClickClose}
          onClickCenter={handleCapture}
          onClickLeft={openRotateModal}
          onClickRight={(): Promise<void> => useRePromptVideo(VIDEO_ID)}
        />
      )}
    </Div100vh>
  )
}

export default ReceiptCamera
