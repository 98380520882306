/* eslint-disable no-return-assign */
import React from 'react'
import clsx from 'clsx'
import ReactModal from 'react-modal'
import ModalAction from '../../molecules/ModalAction'
import styles from './style.module.scss'

type ActionModalProps = {
  title?: string
  isOpen: boolean
  description?: string
  negativeButtonTitle: string
  positiveButtonTitle: string
  onClickNegative: () => void
  onClickPositive: () => void
}
type Props = React.StyledProps & ActionModalProps
const ActionModal: React.StyledFC<Props> = ({
  title,
  description,
  negativeButtonTitle,
  positiveButtonTitle,
  onClickNegative,
  onClickPositive,
  isOpen,
  className,
}: Props) => {
  return (
    <ReactModal
      isOpen={isOpen}
      className={clsx(className, styles.container)}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      overlayClassName={styles.overlay}
      onAfterOpen={(): string => (document.body.style.overflow = 'hidden')}
      onAfterClose={(): string => (document.body.style.overflow = 'unset')}
    >
      <ModalAction
        className={styles.modal}
        title={title}
        description={description}
        negativeButtonTitle={negativeButtonTitle}
        positiveButtonTitle={positiveButtonTitle}
        onClickNegative={onClickNegative}
        onClickPositive={onClickPositive}
      />
    </ReactModal>
  )
}

export default ActionModal
