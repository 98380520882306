import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import styles from './style.module.scss'
import { buttonText, description, title } from './messages'
import LargeTitleDescription from '../../components/molecules/LargeTitleDescription'
import { RootState } from '../../store'
import { cameraGuidePath, topPath } from '../../routes/paths'
import useExpire from '../hooks/useExpire'
import TenantHeaderBottomButtonFullHeight from '../../components/templates/TenantHeaderBottomButtonFullHeight'
import useDate from '../hooks/useDate'
import useMode from '../hooks/useMode'
import useAppHistory from '../../utils/useAppHistory'

function PreCamera(): ReactElement {
  const history = useAppHistory()
  const mode = useMode()
  let { salesDateAsNumber } = useSelector((state: RootState) => state.salesDate)
  if (!salesDateAsNumber) {
    salesDateAsNumber = 0 // TODO消す
    history.push(topPath)
    // return null
  }
  useExpire()
  const handleClick = (): void => {
    history.push(cameraGuidePath)
  }
  return (
    <TenantHeaderBottomButtonFullHeight
      buttonText={buttonText}
      tenant={null}
      className={styles.container}
      onClick={handleClick}
      mode={mode}
      date={useDate()}
      currentStep={1}
    >
      <LargeTitleDescription
        title={title(new Date(salesDateAsNumber))}
        description={description}
        className={styles.txt}
        color="primary"
      />
    </TenantHeaderBottomButtonFullHeight>
  )
}

export default PreCamera
