export const firstDescription =
  '①レシートの角を緑色のガイド線に合わせてください'
export const secondDescription = '②レシートが長い場合は分割してください'
export const thirdDescription =
  'レシートの横幅を緑色のガイド線に合わせてください'

export const verticalButtonText = '縦向き'
export const horizontalButtonText = '横向き'

export const rightButtonText = `カメラが
起動しない方`

export const cancel = 'キャンセル'
export const rotateMessage = (isVertical: boolean): string => {
  return `端末の画面ロックを解除して、
  ${isVertical ? '横向き' : '縦向き'}に傾けてください。`
}

export const centerText = '撮影終了'
export const rightText = '続けて撮影'
export const leftText = '再撮影'
