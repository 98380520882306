import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { getWindowHeight, getWindowWidth } from '../../../utils'
import styles from './style.module.scss'
import ModeHeader from '../../molecules/ModeHeader'
import { ModeEnum } from '../../../features/mode/modeSlice'

type Props = {
  mode?: ModeEnum
  date?: Date
  disabledModeWrapper?: boolean
} & React.StyledProps &
  React.ChildrenProps
const Div100vh: React.StyledFC<Props> = ({
  style,
  className,
  children,
  mode = ModeEnum.Normal,
  date,
  disabledModeWrapper,
}: Props) => {
  const [height, setHeight] = useState(getWindowHeight())
  const [width, setWidth] = useState(getWindowWidth())

  useEffect(() => {
    const handleResize = (): void => {
      setHeight(getWindowHeight())
      setWidth(getWindowWidth())
    }

    window.addEventListener('resize', handleResize)

    return (): void => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div
      className={clsx(className, styles.mode, styles[mode])}
      style={{ ...style, height, width }}
    >
      {!disabledModeWrapper ? (
        <>
          <ModeHeader mode={mode} date={date} />
          <div className={clsx(styles.modeWrapper)}>{children}</div>
        </>
      ) : (
        children
      )}
    </div>
  )
}

export default Div100vh
