import clsx from 'clsx'
import React, { RefObject, useRef, useState } from 'react'
import styles from './style.module.scss'
import Icon from '../../atoms/Icon'
import ReceiptOverlayVideo from '../../organisms/ReceiptOverlayVideo'
import CameraFooter from '../../molecules/CameraFooter'
import FakeImage from '../../atoms/FakeImage'
import CameraNote from '../../molecules/CameraNote'

type ReceiptVideoProps = {
  videoId?: string
  isVertical?: boolean
  prevSrc?: string
  firstDescription: string
  secondDescription: string
  thirdDescription: string
  leftButtonText: string
  rightButtonText: string
  onClickClose: () => void
  onClickCenter: (base64Encoded: string) => void
  onClickLeft: () => void
  onClickRight: () => void
}

type Props = ReceiptVideoProps & React.StyledProps

const ReceiptVideo: React.StyledFC<Props> = ({
  videoId,
  isVertical,
  prevSrc,
  firstDescription,
  secondDescription,
  thirdDescription,
  leftButtonText,
  rightButtonText,
  onClickClose,
  onClickCenter,
  onClickLeft,
  onClickRight,
  style,
  className,
}: Props) => {
  const isFirst = !prevSrc

  // video ref
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [videoRef, setVideoRef] = useState<RefObject<HTMLVideoElement> | null>(
    null
  )
  const handleCanPlay = (element: RefObject<HTMLVideoElement>): void => {
    setVideoRef(element)
  }
  const handleClickCenter = (): void => {
    if (videoRef && canvasRef.current) {
      if (!canvasRef.current || !videoRef.current) {
        window.location.reload()
        return
      }
      const canvas = canvasRef.current.getContext('2d')
      if (!canvas) {
        return
      }
      if (
        videoRef.current.videoHeight === 0 ||
        videoRef.current.videoWidth === 0
      ) {
        return
      }
      // eslint-disable-next-line no-param-reassign
      canvasRef.current.height = videoRef.current.videoHeight
      // eslint-disable-next-line no-param-reassign
      canvasRef.current.width = videoRef.current.videoWidth
      canvas.drawImage(
        videoRef.current,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      )
      const dataUrl = canvasRef.current.toDataURL('image/jpeg', 1)
      if (dataUrl && onClickCenter) {
        onClickCenter(dataUrl)
      }
    }
  }
  return (
    <div className={clsx(className, styles.container)} style={{ ...style }}>
      <div className={styles.header}>
        {!isFirst && (
          <Icon
            icon="close"
            color="white"
            className={styles.closeIcon}
            onClick={onClickClose}
          />
        )}
      </div>
      {isVertical && prevSrc && (
        <FakeImage
          src={prevSrc}
          className={styles.fakeImg}
          width={60}
          height={10}
        />
      )}
      {isFirst ? (
        <div className={styles.noteWrapper}>
          <CameraNote
            text={firstDescription}
            className={clsx(styles.note, styles.first)}
          />
        </div>
      ) : (
        <CameraNote
          text={thirdDescription}
          className={clsx(styles.note, styles.third)}
        />
      )}
      <ReceiptOverlayVideo
        videoId={videoId}
        full={!isFirst}
        onCanPlay={handleCanPlay}
      />
      <canvas ref={canvasRef} className={styles.canvas} />
      <div className={styles.bottom}>
        {isFirst && (
          <CameraNote
            text={secondDescription}
            className={clsx(styles.note, styles.second)}
          />
        )}
        <hr className={styles.hr} />
        <CameraFooter
          className={styles.buttons}
          leftButtonText={leftButtonText}
          rightButtonText={rightButtonText}
          onCenterClick={handleClickCenter}
          onLeftClick={onClickLeft}
          onRightClick={onClickRight}
        />
      </div>
    </div>
  )
}

export default ReceiptVideo
