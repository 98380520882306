import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'
import Typography from '../../atoms/Typography'

type TopCaptionProps = {
  title: string
  subtitle?: string
  color?: AppColor
}
type Props = TopCaptionProps & React.StyledProps
const TopCaption: React.StyledFC<Props> = ({
  title,
  subtitle,
  color = 'primary',
  className,
  style,
}: Props) => {
  return (
    <div
      data-testid="title-subtitle"
      className={clsx(className, styles.container, styles[color])}
      style={style}
    >
      <Typography className={styles.title} data-testid="title">
        {title}
      </Typography>
      {subtitle && (
        <Typography className={styles.subtitle} data-testid="subtitle">
          {subtitle}
        </Typography>
      )}
    </div>
  )
}

export default TopCaption
