export const titleText = `アップロード完了です`
export const descriptionText = `すべての作業が完了しました。
今日もお疲れ様でした。`

export const trainingTitleText = `練習モード終了`
export const trainingDescriptionText = `売上報告を行う場合は、
ホームにもどり報告を行ってください。`

export const resentTitleText = '画像再報告完了です'
export const resentDescriptionText = `すべての作業が完了しました。
今日もお疲れ様でした。`

export const alertMessage = 'こちらは本番報告ではありません'

export const descriptionDisagreeText = `${descriptionText}

※報告内容に相違がありました
後日、精算内容確認表をご確認ください`

export const buttonText = '終了する'
export const buttonTextTrainingMode = 'ホームにもどる'
