import React from 'react'
import clsx from 'clsx'
import BgWrapper from '../../atoms/BgWrapper'
import styles from './style.module.scss'
import Typography from '../../atoms/Typography'
import Button from '../../atoms/Button'

type ModalActionProps = {
  title?: string
  description?: string
  positiveButtonTitle: string
  negativeButtonTitle: string
  onClickPositive: () => void
  onClickNegative: () => void
}

type Props = React.StyledProps & ModalActionProps
const ModalAction: React.StyledFC<Props> = ({
  title,
  description,
  positiveButtonTitle,
  negativeButtonTitle,
  onClickPositive,
  onClickNegative,
  className,
}: Props) => {
  return (
    <BgWrapper
      color="white"
      variant="rounded"
      className={clsx(className, styles.container)}
    >
      {title && (
        <Typography
          variant="middleTitle"
          align="center"
          newLine
          className={styles.title}
        >
          {title}
        </Typography>
      )}
      {description && (
        <Typography
          variant="description"
          align="center"
          newLine
          className={styles.description}
        >
          {description}
        </Typography>
      )}
      <div className={styles.buttons}>
        <Button
          onClick={onClickPositive}
          color="primary"
          variant="contained"
          className={clsx(styles.button, styles.positive)}
        >
          {positiveButtonTitle}
        </Button>
        <Button
          onClick={onClickNegative}
          color="white"
          variant="contained"
          className={clsx(styles.button, styles.negative)}
        >
          {negativeButtonTitle}
        </Button>
      </div>
    </BgWrapper>
  )
}

export default ModalAction
