/* eslint-disable no-return-assign */
import React from 'react'
import clsx from 'clsx'
import ReactModal from 'react-modal'
import ModalError from '../../molecules/ModalError'
import styles from './style.module.scss'

type ErrorModalProps = {
  title?: string
  isOpen: boolean
  description?: string
  buttonTitle?: string
  buttonTitleSecondary?: string
  onClick?: () => void
  onClickSecondary?: () => void
}
type Props = React.StyledProps & ErrorModalProps
const ErrorModal: React.StyledFC<Props> = ({
  title,
  description,
  buttonTitle,
  buttonTitleSecondary,
  onClick,
  onClickSecondary,
  isOpen,
  className,
}: Props) => {
  return (
    <ReactModal
      isOpen={isOpen}
      className={clsx(className, styles.container)}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      overlayClassName={styles.overlay}
      onAfterOpen={(): string => (document.body.style.overflow = 'hidden')}
      onAfterClose={(): string => (document.body.style.overflow = 'unset')}
    >
      <ModalError
        className={styles.modal}
        title={title}
        description={description}
        buttonTitle={buttonTitle}
        buttonTitleSecondary={buttonTitleSecondary}
        onClick={onClick}
        onClickSecondary={onClickSecondary}
      />
    </ReactModal>
  )
}

export default ErrorModal
