import React, { useState } from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'
import { ReactComponent as NoCheckedIcon } from './assets/nochecked.svg'
import { ReactComponent as CheckedIcon } from './assets/checked.svg'

type CheckboxProps = {
  onChanged?: (isChecked: boolean) => void
}

type Props = CheckboxProps & React.StyledProps & React.ChildrenProps

const Checkbox: React.StyledFC<CheckboxProps> = ({
  onChanged,
  style,
  className,
  children,
}: Props) => {
  const [isChecked, setChecked] = useState(false)
  const handleClicked = (): void => {
    const updatedStatus = !isChecked
    setChecked(updatedStatus)
    if (onChanged) {
      onChanged(updatedStatus)
    }
  }
  return (
    <button
      className={clsx(styles.checkbox, className)}
      onClick={handleClicked}
      style={style}
    >
      <div className={styles.icon}>
        {isChecked ? <CheckedIcon /> : <NoCheckedIcon />}
      </div>
      <div className={styles.text}>{children}</div>
    </button>
  )
}

export default Checkbox
