import { useHistory } from 'react-router-dom'
import { useBigQuery } from './useBigQuery'

const useAppHistory = (): {
  push: (path: string) => void
} => {
  const history = useHistory()
  const { sendEvent } = useBigQuery()

  const push = (path: string): void => {
    sendEvent(path)
    history.push(path)
  }

  return { ...history, push }
}

export default useAppHistory
