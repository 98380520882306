import React, { useState } from 'react'
import { releaseNoteUrl } from '../../../constants'
import ActionModal from '../ActionModal'
import { title, positiveButton, negativeButton } from './message'

type Props = {
  registration: ServiceWorkerRegistration
}

export const SWUpdateDialog: React.FC<Props> = ({ registration }: Props) => {
  const [show, setShow] = useState(!!registration.waiting)
  // const style: React.CSSProperties = {
  //   width: '100%',
  //   backgroundColor: 'green',
  // }
  const handleUpdate = (): void => {
    // eslint-disable-next-line no-unused-expressions
    registration.waiting &&
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    setShow(false)
    window.location.reload()
  }

  const handleClickReleaseNote = (): void => {
    window.open(releaseNoteUrl)
    handleUpdate()
  }

  return (
    <ActionModal
      isOpen={show}
      title={title}
      positiveButtonTitle={positiveButton}
      negativeButtonTitle={negativeButton}
      onClickPositive={handleClickReleaseNote}
      onClickNegative={handleUpdate}
    />
  )
}
