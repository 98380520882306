import React from 'react'
import clsx from 'clsx'
import DateInputForm, { DateInputFormProps } from '../../atoms/DateInputForm'
import Icon from '../../atoms/Icon'
import styles from './style.module.scss'
import Typography from '../../atoms/Typography'

type IconDateInputForm = DateInputFormProps & {
  error?: string
}

type Props = IconDateInputForm & React.StyledProps
const IconDateInputForm: React.StyledFC<Props> = ({
  name,
  max,
  min,
  defaultValue,
  required = false,
  autoComplete = 'off',
  onChange,
  onError,
  error,
  style,
  className,
}: Props) => {
  return (
    <div className={clsx(styles.container, className)} style={style}>
      <div className={styles.withIcon}>
        <Icon icon="edit" className={styles.icon} />
        <DateInputForm
          name={name}
          max={max}
          min={min}
          defaultValue={defaultValue}
          required={required}
          autoComplete={autoComplete}
          className={styles.input}
          onChange={onChange}
          onError={onError}
        />
      </div>
      {error && (
        <Typography color="error" className={styles.error}>
          {error}
        </Typography>
      )}
    </div>
  )
}

export default IconDateInputForm
