import React from 'react'
import styles from './style.module.scss'
import Typography from '../../components/atoms/Typography'

export const titleText = (count: number): string => {
  const base = `撮影内容を確認してください。`
  if (count > 1) {
    return `${count}台目レジの\n${base}`
  }
  return base
}
export const recaptureText = `再撮影`
export const upload = 'アップロードして次へ進む'
export const uploadingProgress = `アップロード中です。

通信状態によって
約30秒ほど時間がかかる場合がございます。
ご了承ください。
`
export const actionModalTitle = (index: number): string =>
  `${index + 1}枚目の写真を削除しますか?`
export const actionModalNegativeButton = `キャンセル`
export const actionModalPositiveButton = `削除`

export const checkboxTexts = [
  <Typography variant="description" className={styles.txt} key="0">
    レシート以外の文字が写っていない
  </Typography>,
  <Typography variant="description" className={styles.txt} key="1">
    目視で確認できる文字サイズになっている
  </Typography>,
  <Typography variant="description" className={styles.txt} key="2">
    レシートが歪みや斜めになっていない
  </Typography>,
  <Typography variant="description" className={styles.txt} key="3">
    長いレシートは分割して撮影されている
  </Typography>,
  <Typography variant="description" className={styles.txt} key="4">
    画像に不備がある場合、画像の再送信をご依頼する場合がある旨、確認致しました。
  </Typography>,
]
