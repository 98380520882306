import React, { RefObject, useEffect, useRef } from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'
import useUserMedia from './hooks/useMedia'

// https://developer.mozilla.org/ja/docs/Web/API/MediaDevices/getUserMedia
type CameraProps = {
  id?: string
  cameraOptions?: MediaStreamConstraints
  onCanPlay?: (ref: RefObject<HTMLVideoElement>) => void
  onPlay?: (ref: RefObject<HTMLVideoElement>) => void
  onCameraError?: (err: Error) => void
}

const DEFAULT_CAPTURE_OPTIONS = {
  audio: false,
  video: {
    facingMode: 'environment',
    width: { min: 1280 },
    height: { min: 720 },
  },
}
type Props = CameraProps & React.StyledProps & React.ChildrenProps

const CameraVideo: React.StyledFC<Props> = ({
  id,
  cameraOptions = DEFAULT_CAPTURE_OPTIONS,
  onCanPlay,
  onCameraError,
  onPlay,
  style,
  className,
}: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [mediaStream, startError] = useUserMedia(cameraOptions)
  if (mediaStream && videoRef.current) {
    videoRef.current.srcObject = mediaStream
  }
  useEffect((): void | (() => void | undefined) => {
    if (onCameraError && startError) {
      onCameraError(startError)
    }
  }, [startError, onCameraError])
  const handleCanPlay = (): void => {
    try {
      if (onCanPlay && mediaStream && videoRef) {
        onCanPlay(videoRef)
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  }
  const handleOnPlay = (): void => {
    if (onPlay) {
      onPlay(videoRef)
    }
  }

  return (
    <div className={clsx(className, styles.container)} style={style}>
      <video
        id={id}
        data-testid="camera-video"
        className={styles.video}
        ref={videoRef}
        onPlay={handleOnPlay}
        onCanPlay={handleCanPlay}
        autoPlay
        playsInline
        controls={false}
        muted
        preload="none"
      />
    </div>
  )
}

export default CameraVideo
