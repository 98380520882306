import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiRequest, AppError } from '../../AppTypes'
import { bobaService } from '../services'
import {
  ConsistencyStatement,
  ConsistencyStatements,
  ErrorCodeEnum,
} from '../services/api'
import {
  ApiError,
  AuthorizationError,
  OutOfServiceError,
} from '../services/errors'
import {
  authenticationErrorAppError,
  internalServerErrorAppError,
  outOfServiceAppErr,
} from './common'
import { AppThunkConfig } from '../../store'
import { disableLeaveConfirmation, enableLeaveConfirmation } from '../../utils'

const service = bobaService()

type State = {
  statements: ConsistencyStatement[]
} & ApiRequest

const initialState: State = {
  statements: [],
  apiStatus: 'Initial',
  error: undefined,
}

const handleError = (err: unknown): AppError => {
  if (err instanceof ApiError) {
    switch (err.errorCode) {
      case ErrorCodeEnum.NotFound:
        return {
          needReturnTop: true,
          description: '売上報告に必要なデータの取得に失敗しました。',
          title: '',
        }
      default: {
        return internalServerErrorAppError
      }
    }
  }
  if (err instanceof OutOfServiceError) {
    return outOfServiceAppErr
  }
  if (err instanceof AuthorizationError) {
    return authenticationErrorAppError
  }
  return internalServerErrorAppError
}

export const getConsistencyStatements = createAsyncThunk<
  ConsistencyStatements,
  void,
  AppThunkConfig
>(
  'consistency/getConsistencyStatements',
  async (_, { getState, rejectWithValue }) => {
    try {
      return await service.getConsistencyStatements(getState())
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

const consistencySlice = createSlice({
  name: 'consistency',
  initialState,
  reducers: {
    clearConsistencyStatements: (): State => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConsistencyStatements.pending, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(
      getConsistencyStatements.fulfilled,
      (state, { payload }) => {
        disableLeaveConfirmation()
        return {
          ...state,
          apiStatus: 'Success',
          statements: payload.statements,
        }
      }
    )
    builder.addCase(
      getConsistencyStatements.rejected,
      (state, { payload }): State => {
        disableLeaveConfirmation()
        return {
          ...state,
          apiStatus: 'Failure',
          error: payload,
        }
      }
    )
  },
})

export const { clearConsistencyStatements } = consistencySlice.actions
export default consistencySlice
