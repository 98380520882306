import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'

type TopHeaderProps = {
  text: string
}

type Props = TopHeaderProps & React.StyledProps

const TopHeader: React.StyledFC<TopHeaderProps> = ({
  text,
  style,
  className,
}: Props) => {
  return (
    <div className={clsx(styles.container, className)} style={style}>
      <span className={styles.text}>{text}</span>
    </div>
  )
}

export default TopHeader
