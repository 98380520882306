import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LargeTitleDescription from '../../components/molecules/LargeTitleDescription'
import TenantHeaderBottomButtonFullHeight from '../../components/templates/TenantHeaderBottomButtonFullHeight'
import useTenant from '../hooks/useTenant'
import styles from './style.module.scss'
import {
  alertMessage,
  buttonText,
  description,
  secondButtonText,
  title,
} from './message'
import {
  messageConfirmationPath,
  registerPreCameraPath,
  topWithClearDataPath,
} from '../../routes/paths'

import { incrementRegisterCount } from '../../features/register/registerSlice'
import { RootState } from '../../store'
import {
  postSubmit,
  clearSubmitStatus,
} from '../../features/network/submitSlice'
import ErrorModal from '../../components/organisms/ErrorModal'
import { AppError } from '../../AppTypes'
import { clearAnswers, clearForms } from '../../features/network/formsSlice'
import { clearImage } from '../../features/camera/cameraSlice'
import LoadingModal from '../../components/organisms/LoadingModal'
import useMode, { useTrainingMode } from '../hooks/useMode'
import { finishTraining } from '../../features/mode/modeSlice'
import useDate from '../hooks/useDate'
import useValidate, { ValidateStep } from '../../utils/useValidate'
import useAppHistory from '../../utils/useAppHistory'

function RegisterConfirmation(): ReactElement {
  const tenant = useTenant()
  const history = useAppHistory()
  const mode = useMode()
  const isTraining = useTrainingMode()
  const dispatch = useDispatch()
  const { error, apiStatus } = useSelector((state: RootState) => state.submit)

  useEffect((): void => {
    if (apiStatus === 'Success') {
      history.push(messageConfirmationPath)
    }
  }, [apiStatus])

  const handleClickFinish = async (): Promise<void> => {
    if (isTraining) {
      const finishResult = await dispatch(finishTraining())
      if (
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-expect-error
        finishTraining.fulfilled.match(finishResult)
      ) {
        history.push(messageConfirmationPath)
        return
      }
    }

    await dispatch(postSubmit())
  }

  const handleClickNext = (): void => {
    dispatch(incrementRegisterCount())
    dispatch(clearForms())
    dispatch(clearAnswers())
    dispatch(clearImage())
    history.push(registerPreCameraPath)
  }

  const handleErrorModalButtonClicked = (appError: AppError): void => {
    if (appError.needReturnTop) {
      history.push(topWithClearDataPath)
    } else {
      dispatch(clearSubmitStatus())
    }
  }

  const validate = useValidate()
  useEffect(() => {
    validate(ValidateStep.RegisterConfirmation)
  }, [])

  return (
    <TenantHeaderBottomButtonFullHeight
      className={styles.container}
      buttonText={buttonText}
      buttonColor="green"
      secondButtonText={secondButtonText}
      secondButtonColor="primary"
      secondButtonVariant="text"
      alertMessage={alertMessage}
      mode={mode}
      date={useDate()}
      tenant={tenant}
      onClick={handleClickFinish}
      onClickSecond={handleClickNext}
      currentStep={3}
    >
      {apiStatus === 'Progress' && (
        <LoadingModal isOpen className={styles.loadingModal} />
      )}
      {error && (
        <ErrorModal
          isOpen={Boolean(error)}
          title={error.title}
          description={error.description}
          buttonTitle="OK"
          onClick={(): void => handleErrorModalButtonClicked(error)}
        />
      )}
      <LargeTitleDescription
        className={styles.txt}
        title={title}
        description={description}
        color="primary"
        icon="receipt"
      />
    </TenantHeaderBottomButtonFullHeight>
  )
}

export default RegisterConfirmation
