import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'
import Icon from '../../atoms/Icon'

type ButtonWithIconProps = {
  onClick: () => void
}

type Props = ButtonWithIconProps & React.StyledProps & React.ChildrenProps

const ButtonWithIcon: React.StyledFC<Props> = ({
  onClick,
  children,
  style,
  className,
}: Props) => {
  return (
    <button
      data-testid="button-with-icon"
      type="button"
      style={style}
      className={clsx(styles.btn, className)}
      onClick={onClick}
    >
      <div className={styles.content}>
        {children}
        <Icon icon="chevronRight" className={styles.icon} />
      </div>
      <div className={styles.divider} />
    </button>
  )
}

export default ButtonWithIcon
