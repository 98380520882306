import { BobaService } from './BobaService'
import { BobaServiceFactory } from './BobaServiceFactory'

class BobaServiceProvider {
  private static _instance: BobaServiceProvider

  private readonly bobaService: BobaService

  private constructor() {
    // do something construct...
    this.bobaService = BobaServiceFactory.create()
  }

  /** インスタンスの取得 */
  public static get instance(): BobaServiceProvider {
    // eslint-disable-next-line no-underscore-dangle
    if (!this._instance) {
      // eslint-disable-next-line no-underscore-dangle
      this._instance = new BobaServiceProvider()
    }

    // 生成済みのインスタンスを返す
    // eslint-disable-next-line no-underscore-dangle
    return this._instance
  }

  public provideService(): BobaService {
    return this.bobaService
  }
}

export function bobaService(): BobaService {
  return BobaServiceProvider.instance.provideService()
}
