import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { Tenant, TenantSalesReport } from '../../features/services/api'

export default (): Tenant => {
  const { tenant } = useSelector((state: RootState) => state.auth)
  if (!tenant) {
    throw new Error('tenant was null')
  }
  return tenant
}

export const useHistories = (): TenantSalesReport[] => {
  const { histories } = useSelector((state: RootState) => state.auth)
  if (!histories) {
    return []
  }
  return histories
}
