import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import styles from './style.module.scss'
import Icon from '../../components/atoms/Icon'
import Typography from '../../components/atoms/Typography'
import { titleText, buttonText } from './messages'
import { inputSalesDataPath } from '../../routes/paths'
import useTenant from '../hooks/useTenant'
import useExpire from '../hooks/useExpire'
import TenantHeaderBottomButtonFullHeight from '../../components/templates/TenantHeaderBottomButtonFullHeight'
import { RootState } from '../../store'
import useDate from '../hooks/useDate'
import useMode from '../hooks/useMode'
import useAppHistory from '../../utils/useAppHistory'

function UploadCompletion(): ReactElement | null {
  const history = useAppHistory()
  const tenant = useTenant()
  const mode = useMode()
  const { registerCount } = useSelector((state: RootState) => state.register)
  const handleClick = (): void => {
    history.push(inputSalesDataPath)
  }
  useExpire()
  return (
    <TenantHeaderBottomButtonFullHeight
      tenant={tenant}
      buttonText={buttonText}
      className={styles.container}
      onClick={handleClick}
      mode={mode}
      date={useDate()}
      currentStep={2}
    >
      <Icon icon="rightPointing" className={styles.icon} />
      <Typography variant="largeTitle" align="center" className={styles.text}>
        {titleText(registerCount)}
      </Typography>
    </TenantHeaderBottomButtonFullHeight>
  )
}

export default UploadCompletion
