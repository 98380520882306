import { createSlice } from '@reduxjs/toolkit'

type State = {
  lastActiveTime: number
}

const initialState: State = {
  lastActiveTime: new Date().getTime(),
}

const expireSlice = createSlice({
  name: 'expire',
  initialState,
  reducers: {
    updateActiveTime: (): State => {
      return { lastActiveTime: new Date().getTime() }
    },
  },
})

export const { updateActiveTime } = expireSlice.actions

export default expireSlice
