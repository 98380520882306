export const title = `撮影時の注意事項です。
必ず確認して進んでください。`
export const guideNotes = [
  `レシート以外の文字が映らないように何も無い所で撮影しましょう`,
  `レシートをカメラの枠に合わせる形で撮影しましょう`,
  `レシートが歪んでいたり、斜めにならないように撮影しましょう`,
  `長いレシートの場合は、分割して撮影しましょう。`,
]

export const okPoints = [
  `レシート以外の文字は写っていない`,
  `カメラの枠に合わせたサイズで撮影`,
  `人が確認できる文字サイズで撮影`,
]
export const captionOk = 'OK例'
export const captionNg = 'NG例'

export const ngPoints = [
  `レシート以外の文字が写っている`,
  `カメラの枠にあっていない`,
  `斜めの状態で撮影されている`,
]

export const buttonTitle = `レシート撮影へすすむ`
