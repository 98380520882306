import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'
import Typography from '../../atoms/Typography'
import Space from '../../atoms/Space'

type ImageWithCaptionProps = {
  src: string
  alt: string
  caption: string
  color?: AppColor
}
type Props = ImageWithCaptionProps & React.StyledProps
const ImageWithCaption: React.StyledFC<Props> = ({
  src,
  alt,
  caption,
  color = 'primary',
  style,
  className,
}: Props) => {
  return (
    <div className={clsx(styles.container, className)} style={style}>
      <img src={src} alt={alt} className={styles.img} />
      <Space size="8px" orientation="vertical" />
      <Typography color={color} variant="caption" align="center">
        {caption}
      </Typography>
    </div>
  )
}

export default ImageWithCaption
