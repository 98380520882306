import { BobaService } from '../BobaService'
import {
  Forms,
  FormTypeEnum,
  Images,
  ConsistencyResult,
  ConsistencyResultEnum,
  TenantAndTenantSalesReportHistory,
  ReportStatusEnum,
  ConsistencyStatements,
  NotificationsBySalesDate,
  TenantNotificationBySalesDate,
  SalesDatesWithUnreadNotificationsCount,
} from '../api'
import { sleep } from '../../../utils'
import { RootState } from '../../../store'

export class NoNetworkBobaService implements BobaService {
  // eslint-disable-next-line class-methods-use-this
  async getActive(): Promise<void> {
    return sleep(400)
  }

  // eslint-disable-next-line class-methods-use-this
  async tenantUserSignUp(): Promise<void> {
    return sleep(1000)
  }

  // eslint-disable-next-line class-methods-use-this
  async getSalesDatesWithUnreadNotificationsCount(): Promise<
    SalesDatesWithUnreadNotificationsCount
  > {
    await sleep(1000)
    return Promise.resolve({ count: 1 })
  }

  // eslint-disable-next-line class-methods-use-this
  async getTenantNotificationsBySalesDate(): Promise<
    TenantNotificationBySalesDate[]
  > {
    await sleep(1000)
    return Promise.resolve([
      {
        salesDateId: '123456',
        salesDate: '2023-01-01',
        areAllReadByTenant: false,
        lastCreatedAt: '2023-01-01 10:00:00',
      },
    ])
  }

  // eslint-disable-next-line class-methods-use-this
  async getNotificationsBySalesDate(): Promise<NotificationsBySalesDate> {
    await sleep(1000)
    return Promise.resolve({
      salesDate: '2023-01-01',
      lastCreatedAt: '2023-01-01 10:00:00',
      notifications: [
        {
          id: '123456',
          content: '通知内容1',
          isReadByTenant: false,
          createdAt: '2023-01-01 10:00:00',
        },
      ],
    })
  }

  // eslint-disable-next-line class-methods-use-this
  async patchNotifications(): Promise<void> {
    await sleep(1000)
  }

  // eslint-disable-next-line class-methods-use-this
  async getConsistencyStatements(): Promise<ConsistencyStatements> {
    await sleep(1000)
    return Promise.resolve({
      statements: [
        {
          id: '123456',
          name: '差分',
          leftItems: [
            {
              id: 'id1',
              name: '純売上(税抜)',
            },
          ],
          rightItems: [
            {
              id: 'id2',
              name: '客数',
            },
          ],
        },
      ],
    })
  }

  // eslint-disable-next-line class-methods-use-this
  async getInputForm(): Promise<Forms> {
    await sleep(1000)
    return Promise.resolve({
      forms: [
        {
          id: 'id1',
          index: 0,
          label: '純売上(税抜)',
          type: FormTypeEnum.Number,
          suffix: '円',
          required: true,
        },
        {
          id: 'id2',
          index: 1,
          label: '客数',
          type: FormTypeEnum.Number,
          suffix: '人',
          required: true,
        },
      ],
    })
  }

  // eslint-disable-next-line class-methods-use-this
  async getConsistencyResults(): Promise<ConsistencyResult> {
    await sleep(400)
    return {
      result: ConsistencyResultEnum.Pass,
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async getTenantAuth(): Promise<[TenantAndTenantSalesReportHistory, string]> {
    await sleep(1000)
    return [
      {
        tenant: {
          id: '123456',
          ocrEnabled: true,
          storeCode: '00001',
          storeName: 'マルイ店舗',
          tenantCode: '0123',
          tenantName: '研修用',
          pcEnabled: false,
        },
        tenantSalesReportHistory: [
          {
            id: '123456',
            date: '2023-01-01',
            reportStatus: ReportStatusEnum.Waiting,
            updatedAt: null,
          },
          {
            id: '123456',
            date: '2023-01-02',
            reportStatus: ReportStatusEnum.ImageResent,
            updatedAt: '2023-01-01 10:00:00',
          },
          {
            id: '123456',
            date: '2023-01-03',
            reportStatus: ReportStatusEnum.Reported,
            updatedAt: '2023-01-01 22:00:00',
          },
          {
            id: '123456',
            date: '2023-01-04',
            reportStatus: ReportStatusEnum.FinalizedReportSubmitted,
            updatedAt: '2023-01-01 10:00:00',
          },
          {
            id: '123456',
            date: '2023-01-04',
            reportStatus: ReportStatusEnum.FinalizedReportSynced,
            updatedAt: '2023-01-01 10:00:00',
          },
        ],
      },
      'token',
    ]
  }

  // eslint-disable-next-line class-methods-use-this
  async postSubmit(): Promise<void> {
    return sleep(1400)
  }

  // eslint-disable-next-line class-methods-use-this
  async postImages(
    images: Images,
    state: RootState,
    progressCallback?: (progress: number) => void
  ): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const cb = progressCallback || ((): void => {})
    cb(10)
    await sleep(400)
    cb(40)
    await sleep(400)
    cb(80)
    await sleep(400)
    return cb(100)
  }

  // eslint-disable-next-line class-methods-use-this
  async postResentImages(
    images: Images,
    state: RootState,
    progressCallback?: (progress: number) => void
  ): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const cb = progressCallback || ((): void => {})
    cb(10)
    await sleep(400)
    cb(40)
    await sleep(400)
    cb(80)
    await sleep(400)
    return cb(100)
  }

  // eslint-disable-next-line class-methods-use-this
  async postInputForm(): Promise<void> {
    await sleep(1000)
    return Promise.resolve(undefined)
  }

  // eslint-disable-next-line class-methods-use-this
  async postSalesDate(): Promise<void> {
    await sleep(1000)
    return Promise.resolve(undefined)
  }

  // eslint-disable-next-line class-methods-use-this
  async postAppSalesdateSalesDateIdRegisters(): Promise<void> {
    await sleep(1000)
    return Promise.resolve(undefined)
  }

  // eslint-disable-next-line class-methods-use-this
  async postAppSalesdateRegisters(): Promise<void> {
    await sleep(1000)
  }

  // eslint-disable-next-line class-methods-use-this
  async putSalesReportMessage(): Promise<void> {
    await sleep(1000)
  }

  // eslint-disable-next-line class-methods-use-this
  async putTrainingSubmit(): Promise<void> {
    await sleep(1000)
  }

  // eslint-disable-next-line class-methods-use-this
  async postTrainingInputForm(): Promise<void> {
    await sleep(1000)
  }
}
