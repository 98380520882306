import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'

type BgWrapper = {
  color: 'gray' | 'white' | 'error' | 'primaryTranslucent'
  variant?: 'rounded'
}

type Props = BgWrapper & React.StyledProps & React.ChildrenProps
const BgWrapper: React.StyledFC<Props> = ({
  color,
  variant = undefined,
  style,
  className,
  children,
}: Props) => {
  return (
    <div
      className={clsx(
        className,
        styles.wrapper,
        styles[color],
        variant && styles[variant]
      )}
      style={style}
    >
      {children}
    </div>
  )
}

export default BgWrapper
