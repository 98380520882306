import { useDispatch, useSelector } from 'react-redux'
import { useLayoutEffect, useState } from 'react'
import { RootState, persistor } from '../../store'
import { topWithClearDataPath } from '../../routes/paths'
import { updateActiveTime } from '../../features/expire/expireSlice'
import { expireTimeoutMilliSec } from '../../constants'

const message = `30分間操作がなかったため、最初から操作をお願いします。`

export default (): void => {
  const { lastActiveTime } = useSelector((state: RootState) => state.expire)
  const [alreadyDo, setAlreadyDo] = useState(false)
  const dispatch = useDispatch()
  useLayoutEffect(() => {
    if (alreadyDo) {
      return
    }
    const now = new Date().getTime()
    if (now - lastActiveTime >= expireTimeoutMilliSec) {
      // eslint-disable-next-line no-alert
      alert(message)
      persistor.purge().then((): void => {
        document.location.href = topWithClearDataPath
      })
    } else {
      dispatch(updateActiveTime())
      setAlreadyDo(true)
    }
  }, [alreadyDo, setAlreadyDo, lastActiveTime, dispatch])
}
