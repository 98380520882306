import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiRequest, AppError } from '../../AppTypes'
import { AppThunkConfig } from '../../store'
import { disableLeaveConfirmation, enableLeaveConfirmation } from '../../utils'
import {
  authenticationErrorAppError,
  internalServerErrorAppError,
  outOfServiceAppErr,
} from '../network/common'
import { bobaService } from '../services'
import { ErrorCodeEnum } from '../services/api'
import {
  ApiError,
  AuthorizationError,
  OutOfServiceError,
} from '../services/errors'

const service = bobaService()

type State = {
  registerCount: number
} & ApiRequest

const initialState: State = {
  registerCount: 1,
  apiStatus: 'Initial',
  error: undefined,
}

const handleError = (err: Error): AppError => {
  if (err instanceof ApiError) {
    switch (err.errorCode) {
      case ErrorCodeEnum.InternalServerError:
        return internalServerErrorAppError
      case ErrorCodeEnum.InvalidSalesDate:
        return {
          needReturnTop: true,
          description:
            '管理側で報告値が確定されたため、画像再送信が無効になりました。',
          title: '',
        }
      default:
        return {
          needReturnTop: false,
          description:
            '売上データを登録中にエラーが発生しました。時間をおいて再度お試しください。',
          title: '売上登録エラー',
        }
    }
  } else if (err instanceof OutOfServiceError) {
    return outOfServiceAppErr
  } else if (err instanceof AuthorizationError) {
    return authenticationErrorAppError
  }
  return internalServerErrorAppError
}

export const postAppSalesdateRegisters = createAsyncThunk<
  void,
  void,
  AppThunkConfig
>('register/create', async (_, { getState, rejectWithValue }) => {
  try {
    return await service.postAppSalesdateRegisters(getState())
  } catch (err) {
    return rejectWithValue(handleError(err))
  }
})

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    incrementRegisterCount: (state): State => {
      return {
        ...state,
        registerCount: state.registerCount + 1,
      }
    },
    decrementRegisterCount: (state): State => {
      return {
        ...state,
        registerCount: state.registerCount - 1,
      }
    },
    clearRegisterCount: (): State => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postAppSalesdateRegisters.pending, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(postAppSalesdateRegisters.fulfilled, (state) => {
      disableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Success',
      }
    })
    builder.addCase(postAppSalesdateRegisters.rejected, (state, action) => {
      disableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Failure',
        error: action.payload,
      }
    })
  },
})

export const {
  incrementRegisterCount,
  decrementRegisterCount,
  clearRegisterCount,
} = registerSlice.actions

export default registerSlice
