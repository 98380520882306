import React from 'react'
import clsx from 'clsx'
import ListItem from '../../atoms/ListItem'
import styles from './style.module.scss'
import Typography from '../../atoms/Typography'

interface OrderListProps<T> {
  items: T[]
  color?: AppColor
}

type Props<T> = OrderListProps<T> & React.StyledProps
type MyComponentI<T> = React.StyledFC<Props<T>>
const UnOrderList: MyComponentI<string | number> = ({
  items,
  color = 'white',
  style,
  className,
}: Props<string | number>) => {
  return (
    <ul
      className={clsx(styles.list, styles[color], className)}
      style={style}
      data-testid="list"
    >
      {items.map((item) => (
        <ListItem key={item} className={styles.item}>
          <Typography
            variant="description"
            align="left"
            className={styles.txt}
            color={color}
          >
            {item}
          </Typography>
        </ListItem>
      ))}
    </ul>
  )
}
export default UnOrderList
