import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'

type FrameProps = {}

type Props = FrameProps & React.StyledProps & React.ChildrenProps
const Frame: React.StyledFC<Props> = ({ style, className }: Props) => {
  return (
    <div
      data-testid="frame"
      style={style}
      className={clsx(className, styles.frame)}
    />
  )
}

export default Frame
