import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import Typography from '../Typography'

type UnreadBadgeProps = {
  title: string
} & React.StyledProps

const UnreadBadge: React.FC<UnreadBadgeProps> = ({
  className,
  title,
}: UnreadBadgeProps) => {
  return (
    <div className={clsx(className, styles.root)}>
      <Typography className={styles.title}>{title}</Typography>
    </div>
  )
}

export default UnreadBadge
