export const titleText = (count: number): string => {
  const base = `入力内容を
確認してください。`
  if (count > 1) {
    return `${count}台目レジの${base}`
  }
  return base
}
export const loadingModalText = `通信状態によって
約30秒ほど時間がかかる場合がございます。
ご了承ください。`

export const fixButtonText = '修正する'
export const buttonText = '次へ進む'
