const useRePromptVideo = async (id: string): Promise<void> => {
  const stream = await navigator.mediaDevices.getUserMedia({
    audio: false, // 録音は不要
    video: {
      facingMode: 'environment', // リアカメラの権限
    },
  })
  const video = document.querySelector<HTMLVideoElement>(`video#${id}`)

  if (!video) return
  video.srcObject = stream
}

export default useRePromptVideo
