import { useEffect, useState } from 'react'
import { Form, FormTypeEnum } from '../../../features/services/api'
import { FormAnswerWrapper } from '../../../AppTypes'

type LabelValue = {
  id: string
  label: string
  value: string
}

const wrapPreSuf = (str: string, prefix?: string, suffix?: string): string => {
  let pStr = ''
  if (prefix) {
    pStr = `${prefix} `
  }
  let sStr = ''
  if (suffix) {
    sStr = ` ${suffix}`
  }
  return `${pStr}${str}${sStr}`
}

const toValueStr = (
  value: string,
  type: FormTypeEnum,
  prefix?: string,
  suffix?: string
): string => {
  switch (type) {
    case 'text':
      return wrapPreSuf(value, prefix, suffix)
    case 'number':
      return wrapPreSuf(parseInt(value, 10).toLocaleString(), prefix, suffix)
    default:
      throw new Error(`not support type: ${type}`)
  }
}

export default (forms: Form[], answers: FormAnswerWrapper[]): LabelValue[] => {
  const [lv, setLabelValues] = useState<LabelValue[]>([])
  useEffect((): void => {
    const labelValues = answers.map(
      (answer): LabelValue => {
        const form = forms.find((f) => f.id === answer.formId)
        if (!form) {
          // ありえないはず
          return {
            id: '',
            label: '',
            value: '',
          }
        }
        return {
          id: form.id,
          label: form.label,
          value:
            (answer.value &&
              toValueStr(answer.value, form.type, form.prefix, form.suffix)) ||
            wrapPreSuf('', form.prefix, form.suffix),
        }
      }
    )
    setLabelValues(labelValues)
  }, [forms, answers])
  return lv
}
