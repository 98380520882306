import React, { CSSProperties } from 'react'
import clsx from 'clsx'
import InputForm from '../../atoms/InputForm'
import styles from './style.module.scss'
import Typography from '../../atoms/Typography'
import { FormTypeEnum } from '../../../features/services/api'

type DecoratedInputFormProps = {
  id: string
  // prefix?: string
  suffix?: string
  label: string
  name: string
  defaultValue?: string
  errorMessage?: string
  variant: FormTypeEnum
  // required?: boolean
  onChange?: (name: string, value: string) => void
  onEnter?: () => void
}

type Props = DecoratedInputFormProps & React.StyledProps

type FormPattern = {
  type: FormTypeEnum
  pattern?: string
}

const variantMapping = {
  text: {
    v: 'text',
    type: 'text',
    pattern: '.*',
  },
  number: {
    v: 'number',
    type: 'text',
  },
  // todo
  date: {
    v: 'number',
    type: 'text',
  },
  // todo
  email: {
    v: 'number',
    type: 'text',
  },
}

// todo: prefix対応
const DecoratedInputForm: React.StyledFC<Props> = ({
  id,
  name,
  label,
  defaultValue,
  suffix,
  variant,
  // required,
  errorMessage,
  onChange,
  onEnter,
  style,
  className,
}: Props) => {
  const pattern = variantMapping[variant] as FormPattern
  const su = {
    '--suffix': `"${suffix}"`,
  } as CSSProperties
  return (
    <div className={clsx(className, styles.container)} style={style}>
      <label htmlFor={id}>
        <Typography variant="smallTitle" align="left" className={styles.label}>
          {label}
        </Typography>
        <div className={styles.wrapper} style={su}>
          <span>
            <InputForm
              id={id}
              defaultValue={defaultValue}
              name={name}
              className={clsx(styles.form, styles[variant])}
              // required={required}
              onChange={onChange}
              onEnter={onEnter}
              variant={variant}
              pattern={pattern.pattern}
            />
          </span>
        </div>
        {errorMessage && (
          <Typography
            variant="smallTitle"
            color="error"
            className={styles.errorMessage}
          >
            {errorMessage}
          </Typography>
        )}
      </label>
    </div>
  )
}

export default DecoratedInputForm
