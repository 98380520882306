import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'
import Icon from '../../atoms/Icon'
import TenantShopName from '../../molecules/TenantShopName'
import Space from '../../atoms/Space'

type TenantNameIconProps = {
  shopName: string
  tenantName: string
}

type Props = TenantNameIconProps & React.StyledProps

const TenantNameIcon: React.StyledFC<Props> = ({
  shopName,
  tenantName,
  style,
  className,
}: Props) => {
  return (
    <div style={style} className={clsx(className, styles.container)}>
      <Icon icon="shop" className={styles.icon} />
      <Space orientation="vertical" size="24px" />
      <TenantShopName shopName={shopName} tenantName={tenantName} />
    </div>
  )
}

export default TenantNameIcon
