import dayjs from 'dayjs'

export const title = (date: Date): string => {
  // eslint-disable-next-line no-irregular-whitespace
  return `次に　${dayjs(date).format('YYYY年MM月DD日')}の
精算レシートの写真を
アップロードしていきましょう。`
}

export const description = `これから撮影する画像は、
翌日担当者が一枚ずつ確認を行います。

画像の質が悪い場合は、
画像の再送信をご依頼する場合があります。`

export const buttonText = `レシート撮影へすすむ`
