import React from 'react'
import clsx from 'clsx'
import BgWrapper from '../../atoms/BgWrapper'
import Typography from '../../atoms/Typography'
import styles from './style.module.scss'

type ErrorJumbotronProps = {
  text: string
}

type Props = React.StyledProps & ErrorJumbotronProps
const ErrorJumbotron: React.StyledFC<Props> = ({
  text,
  style,
  className,
}: Props) => {
  return (
    <BgWrapper
      color="error"
      className={clsx(styles.container, className)}
      style={style}
    >
      <Typography color="white" variant="description" align="center" newLine>
        {text}
      </Typography>
    </BgWrapper>
  )
}

export default ErrorJumbotron
