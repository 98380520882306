import clsx from 'clsx'
import React from 'react'
import styles from './style.module.scss'
import { ReactComponent as CheckSvg } from './assets/check.svg'

type StepperProps = {
  count: number
  current: number
  lastLabel?: string
}

type Props = StepperProps & React.StyledProps

const Stepper: React.StyledFC<Props> = ({
  style,
  count,
  current,
  lastLabel,
  className,
}: Props) => {
  return (
    <div className={clsx(className, styles.root)} style={style}>
      {Array.from({ length: count }).map((_, i) => {
        return i < current ? (
          <div className={clsx(styles.check)}>
            <CheckSvg />
          </div>
        ) : (
          <div
            key={String(i)}
            className={clsx(styles.step, i === current && styles.current)}
          >
            <span className={styles.number}>{i + 1}</span>
          </div>
        )
      })}
      {lastLabel && <div className={styles.step}>{lastLabel}</div>}
    </div>
  )
}

export default Stepper
