/* eslint-disable no-return-assign */
import React from 'react'
import clsx from 'clsx'
import ReactModal from 'react-modal'
import styles from './style.module.scss'
import ModalMessage from '../../molecules/ModalMessage'

type MessageModalProps = {
  message: string
  buttonTitle: string
  onClick: () => void
  isOpen: boolean
}
type Props = React.StyledProps & MessageModalProps
const MessageModal: React.StyledFC<Props> = ({
  message,
  buttonTitle,
  onClick,
  isOpen,
  className,
}: Props) => {
  return (
    <ReactModal
      isOpen={isOpen}
      className={clsx(className, styles.container)}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      overlayClassName={styles.overlay}
      onAfterOpen={(): string => (document.body.style.overflow = 'hidden')}
      onAfterClose={(): string => (document.body.style.overflow = 'unset')}
    >
      <ModalMessage
        className={styles.modal}
        message={message}
        buttonTitle={buttonTitle}
        onClick={onClick}
      />
    </ReactModal>
  )
}

export default MessageModal
