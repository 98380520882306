import React, { ReactElement, useCallback, useEffect } from 'react'
import styles from './style.module.scss'
import Icon from '../../components/atoms/Icon'
import {
  titleText,
  buttonText,
  descriptionText,
  descriptionDisagreeText,
  trainingTitleText,
  trainingDescriptionText,
  resentDescriptionText,
  resentTitleText,
  buttonTextTrainingMode,
  alertMessage,
} from './messages'
import { topWithClearDataPath } from '../../routes/paths'
import LargeTitleDescription from '../../components/molecules/LargeTitleDescription'
import { persistor } from '../../store'
import useTenantIfExists from './hooks/useTenantIfExists'
import useQuery from '../hooks/useQuery'
import TenantHeaderBottomButtonFullHeight from '../../components/templates/TenantHeaderBottomButtonFullHeight'
import useMode from '../hooks/useMode'
import useDate from '../hooks/useDate'
import { ModeEnum } from '../../features/mode/modeSlice'
import useValidate, { ValidateStep } from '../../utils/useValidate'
import { useBigQuery } from '../../utils/useBigQuery'

function Submitted(): ReactElement | null {
  const tenant = useTenantIfExists()
  const mode = useMode()
  const isTraining = mode === ModeEnum.Training
  const isNormal = mode === ModeEnum.Normal
  const { sendEvent } = useBigQuery()

  const handleClick = (): void => {
    persistor.purge().then((): void => {
      sendEvent(topWithClearDataPath)
      document.location.href = topWithClearDataPath
    })
  }
  const query = useQuery()
  const isDisagreement = Boolean(query.get('disagreement'))

  const validate = useValidate()
  useEffect(() => {
    const isValid = validate(ValidateStep.Submitted)
    if (isValid) {
      sendEvent('Visited Submitted Page')
    } else {
      sendEvent('Visited Submitted Page (Validate Error)')
    }
  }, [])

  const title = (): string => {
    switch (mode) {
      case ModeEnum.Training:
        return trainingTitleText
      case ModeEnum.Resent:
        return resentTitleText
      default:
        return titleText
    }
  }

  const description = (): string => {
    switch (mode) {
      case ModeEnum.Training:
        return trainingDescriptionText
      case ModeEnum.Resent:
        return resentDescriptionText
      default:
        return isDisagreement ? descriptionDisagreeText : descriptionText
    }
  }

  const buttonTitle = useCallback(
    (usingMode: ModeEnum): string => {
      if (usingMode === ModeEnum.Training) {
        return buttonTextTrainingMode
      }
      return buttonText
    },
    [mode]
  )

  return (
    <TenantHeaderBottomButtonFullHeight
      buttonText={buttonTitle(mode)}
      tenant={tenant}
      className={styles.container}
      mode={mode}
      alertMessage={isTraining ? alertMessage : undefined}
      buttonColor={isTraining ? 'green' : undefined}
      date={useDate()}
      onClick={handleClick}
    >
      {isNormal && <Icon icon="partyPopper" className={styles.icon} />}
      <LargeTitleDescription
        title={title()}
        align="center"
        className={styles.text}
        description={description()}
      />
    </TenantHeaderBottomButtonFullHeight>
  )
}

export default Submitted
