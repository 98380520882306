import { BobaService } from './BobaService'
import { NoNetworkBobaService } from './impl/NoNetworkBobaService'
import { getBaseUrl, isNetworkEnabled } from '../../constants'
import { ApiBobaService } from './impl/ApiBobaService'

export class BobaServiceFactory {
  static create(): BobaService {
    if (isNetworkEnabled) {
      return new ApiBobaService(getBaseUrl())
    }
    return new NoNetworkBobaService()
  }
}
