export const title = `他に売上報告の必要な
レシートはありますか？`

export const description = `レジが複数存在する場合は、「他の精算レシートを撮影する」をタップして再度作業へ進んでください。`

export const buttonText = '次へ進む'

export const secondButtonText = '他レジの精算レシートを撮影する'

export const alertMessage = 'まだ報告完了しておりません'
