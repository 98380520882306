import { FormAnswerWrapper } from '../../AppTypes'
import { ConsistencyStatement } from '../../features/services/api'
import { Result as PrecheckResult } from '../../components/molecules/SalesDataFormFooter'

const getAnswerValue = (
  answers: FormAnswerWrapper[],
  items: string[]
): number => {
  return answers
    .filter((answer) => {
      return items.includes(answer.formId)
    })
    .map((answer) => Number(answer.value ?? 0))
    .reduce((acc, cur) => acc + cur)
}

export const getPrecheckResult = (
  statements: ConsistencyStatement[],
  answers: FormAnswerWrapper[]
): PrecheckResult[] => {
  return statements.map((statement) => {
    const leftItems = statement.leftItems.map((item) => item.id)
    const rightItems = statement.rightItems.map((item) => item.id)

    const leftValue = getAnswerValue(answers, leftItems)
    const rightValue = getAnswerValue(answers, rightItems)

    return {
      title: statement.name,
      // NOTE: 左辺 - 右辺 で計算している。ビジネス都合で逆転する可能性はあり。
      value: leftValue - rightValue,
      unit: '円',
    }
  })
}

export default (
  statements: ConsistencyStatement[],
  answers: FormAnswerWrapper[]
): PrecheckResult[] => {
  const filteredStatements = statements.filter((statement) => {
    return statement.leftItems.length > 0 && statement.rightItems.length > 0
  })

  return getPrecheckResult(filteredStatements, answers)
}
