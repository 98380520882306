import React, { RefObject } from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'
import CameraVideo from '../../atoms/CameraVideo'
import CaptureOverlay from '../CaptureOverlay'

type ReceiptOverlayVideoProps = {
  videoId?: string
  full?: boolean
  onCanPlay?: (ref: RefObject<HTMLVideoElement>) => void
}

type Props = ReceiptOverlayVideoProps & React.StyledProps & React.ChildrenProps

const ReceiptOverlayVideo: React.StyledFC<Props> = ({
  videoId,
  full,
  onCanPlay,
  style,
  className,
}: Props) => {
  return (
    <div style={style} className={clsx(className, styles.container)}>
      <CaptureOverlay full={full} className={styles.overlay} />
      <CameraVideo
        id={videoId}
        className={styles.video}
        onCanPlay={onCanPlay}
      />
    </div>
  )
}

export default ReceiptOverlayVideo
