import React from 'react'
import clsx from 'clsx'

type ListItemProps = {}

type Props = ListItemProps & React.StyledProps & React.ChildrenProps
const ListItem: React.StyledFC<Props> = ({
  children,
  className,
  style,
}: Props) => {
  return (
    <li className={clsx(className)} style={style}>
      {children}
    </li>
  )
}

export default ListItem
