import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UAParser } from 'ua-parser-js'
import { useHistory } from 'react-router-dom'
import TopHeader from '../../components/organisms/TopHeader'
import styles from './style.module.scss'
import TitleSubtitle from '../../components/organisms/TitleSubtitle'
import Icon from '../../components/atoms/Icon'
import Button from '../../components/atoms/Button'
import TopFlow from '../../components/organisms/TopFlow'
import Space from '../../components/atoms/Space'
import {
  headerTitle,
  middleSubTitle,
  middleTitle,
  flowTitle,
  flows,
  qrCaptureButtonTitle,
  faqButtonTitle,
  bugReportButtonTitle,
  releaseNoteButtonTitle,
  isInternalServerErrorTittle,
  internalErrorTitle,
  forceReloadTitle,
  forceReloadDescription,
} from './messages'
import { readerPath } from '../../routes/paths'
import { updateActiveTime } from '../../features/expire/expireSlice'
import { persistor, RootState } from '../../store'
import useClearData from '../hooks/useClearData'
import { getActive } from '../../features/network/activeSlice'
import ErrorJumbotron from '../../components/molecules/ErrorJumbotron'
import ButtonWithIcon from '../../components/molecules/ButtonWithIcon'
import { getBugReportUrl } from '../../utils/bugReportUrl'
import {
  ForceUpdateTimeoutMilSec,
  faqUrl,
  releaseNoteUrl,
} from '../../constants'
import BgWrapper from '../../components/atoms/BgWrapper'
import Typography from '../../components/atoms/Typography'
import ErrorModal from '../../components/organisms/ErrorModal'

function Top(): ReactElement {
  const history = useHistory()
  const dispatch = useDispatch()
  const userAgentParser = new UAParser()
  dispatch(updateActiveTime())
  const handleQRCapture = (): void => {
    dispatch(updateActiveTime())
    history.push(readerPath)
  }
  useClearData()
  persistor.purge().then((): void => {
    //
  })
  const { isActive, error } = useSelector((state: RootState) => state.active)
  useEffect(() => {
    dispatch(getActive())
  }, [dispatch])
  const handleClickFaq = (): void => {
    window.open(faqUrl)
  }
  const appVersion = String(process.env.REACT_APP_VERSION)
  const handleClickBugReport = (): void => {
    window.open(getBugReportUrl(userAgentParser.getUA(), appVersion))
  }
  const handleClickReleaseNote = (): void => {
    window.open(releaseNoteUrl)
  }

  const [lastActiveTime, setLastActiveTime] = React.useState<number>(Date.now())
  useEffect(() => {
    const now = Date.now()
    setLastActiveTime(now)
  }, [])

  // NOTE: Increment the count to make setTimeout an infinite loop.
  const [count, setCount] = React.useState<number>(0)
  const isForceShutdown = sessionStorage.getItem('isForceShutdown')
  window.setTimeout(() => {
    const now = Date.now()
    if (now - lastActiveTime > ForceUpdateTimeoutMilSec && !isForceShutdown) {
      sessionStorage.setItem('isForceShutdown', 'true')
    }
    setCount(count + 1)
  }, 2000)

  const environmentInfo = (): ReactElement => {
    return (
      <BgWrapper color="gray" className={styles.environment}>
        <Typography variant="note" align="center" className={styles.note}>
          {process.env.REACT_APP_ENV}
        </Typography>
      </BgWrapper>
    )
  }

  return (
    <div className={styles.container} data-testid="top-page">
      {window.matchMedia('(display-mode: standalone)').matches &&
        isForceShutdown && (
          <ErrorModal
            isOpen
            title={forceReloadTitle}
            description={forceReloadDescription}
          />
        )}
      {process.env.REACT_APP_ENV !== 'production' && environmentInfo()}
      {error && !error?.isInternalServerError && (
        <ErrorJumbotron text={error.description} />
      )}
      <TopHeader text={headerTitle} className={styles.header} />
      <Space size="64px" orientation="vertical" />
      {error?.isInternalServerError ? (
        <TitleSubtitle
          className={styles.title}
          title={internalErrorTitle}
          subtitle={isInternalServerErrorTittle}
        />
      ) : (
        <>
          <Icon icon="smile" color="primary" className={styles.icon} />
          <Space size="45px" orientation="vertical" />
          <TitleSubtitle title={middleTitle} subtitle={middleSubTitle} />
          <Space size="30px" orientation="vertical" />
          <Button
            disabled={!isActive}
            className={styles.button}
            onClick={handleQRCapture}
            variant="contained"
            color="primary"
          >
            {qrCaptureButtonTitle}
          </Button>
        </>
      )}
      <Space size="48px" orientation="vertical" />
      <div className={styles.bottom}>
        <ButtonWithIcon onClick={handleClickReleaseNote}>
          {releaseNoteButtonTitle}
        </ButtonWithIcon>
        <ButtonWithIcon onClick={handleClickFaq}>
          {faqButtonTitle}
        </ButtonWithIcon>
        <ButtonWithIcon onClick={handleClickBugReport}>
          {bugReportButtonTitle}
        </ButtonWithIcon>
        <Space size="68px" orientation="vertical" />
        <TopFlow title={flowTitle} items={flows} />
      </div>
    </div>
  )
}

export default Top
