import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiRequest, AppError } from '../../AppTypes'
import { AppThunkConfig } from '../../store'
import { disableLeaveConfirmation, enableLeaveConfirmation } from '../../utils'
import {
  ErrorCodeEnum,
  Store,
  Tenant,
  TenantAndTenantSalesReportHistory,
  TenantDetail,
  TenantSalesReport,
} from '../services/api'
import { ApiError, OutOfServiceError } from '../services/errors'
import { internalServerErrorAppError, outOfServiceAppErr } from './common'
import { bobaService } from '../services'

const service = bobaService()

type State = {
  orgCode: string | null
  store: Store | null
  tenant: Tenant | null
  tenantDetail?: TenantDetail
  histories?: TenantSalesReport[]
  token: string | null
} & ApiRequest

const initialState: State = {
  apiStatus: 'Initial',
  orgCode: null,
  store: null,
  tenant: null,
  token: null,
  error: undefined,
}

interface TenantHistoryWithToken {
  tenantWithHistory: TenantAndTenantSalesReportHistory
  token: string
}

interface TenantWithToken {
  tenant: Tenant
  token: string
}

const handleError = (err: Error): AppError => {
  if (err instanceof ApiError) {
    switch (err.errorCode) {
      case (ErrorCodeEnum.InvalidInput,
      ErrorCodeEnum.InvalidTenantCode,
      ErrorCodeEnum.NotFound):
        return {
          needReturnTop: false,
          description:
            '読み取ったQRコードは不明な認証コードです。別のQRコードをお試しください。',
          title: '認証エラー',
        }
      case ErrorCodeEnum.InvalidTenantStatus:
        return {
          needReturnTop: true,
          description:
            'ご利用のテナントは現在利用停止中です。お手数ですが、管理者にお問い合わせください。',
          title: '利用停止中',
        }
      default:
        return {
          needReturnTop: false,
          description: '認証中にエラーが発生しました。',
          title: '認証エラー',
        }
    }
  } else if (err instanceof OutOfServiceError) {
    return outOfServiceAppErr
  }
  return internalServerErrorAppError
}

export const tenantAuth = createAsyncThunk<
  TenantHistoryWithToken,
  string,
  AppThunkConfig
>('tenants/auth', async (arg, thunkAPI) => {
  const { getState, rejectWithValue } = thunkAPI
  try {
    const [tenantWithHistory, token] = await service.getTenantAuth(
      arg,
      getState()
    )
    return { tenantWithHistory, token }
  } catch (err) {
    return rejectWithValue(handleError(err))
  }
})

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearTenantInfo: (): State => {
      return initialState
    },
    recursiveScan: (state): State => {
      return {
        ...state,
        apiStatus: 'Failure',
        error: {
          title: '', // NOTE: title なし
          description: `「アプリ遷移用」のQRコードを読み取っています。
「テナント認証用」のQRコードにて再度認証を行なってください。`,
          needReturnTop: false,
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(tenantAuth.pending, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(tenantAuth.fulfilled, (state, arg) => {
      disableLeaveConfirmation()
      const { tenantWithHistory, token } = arg.payload
      const orgCode = arg.meta.arg.substring(0, 3)

      const {
        tenant,
        tenantDetail,
        tenantSalesReportHistory: histories,
      } = tenantWithHistory
      return {
        ...state,
        orgCode,
        tenant,
        histories,
        tenantDetail,
        apiStatus: 'Success',
        token,
      }
    })
    builder.addCase(tenantAuth.rejected, (state, action) => {
      disableLeaveConfirmation()
      return {
        ...state,
        error: action.payload,
        apiStatus: 'Failure',
      }
    })
  },
})

export const { clearTenantInfo, recursiveScan } = authSlice.actions

export default authSlice
