import clsx from 'clsx'
import React from 'react'
import styles from './style.module.scss'

type FakeImageProps = {
  src: string
  height: number
  width: number
}

type Props = FakeImageProps & React.StyledProps

const FakeImage: React.StyledFC<Props> = ({
  src,
  height,
  width,
  style,
  className,
}: Props) => {
  const heightSize = `${height}%`
  const widthSize = `${width}%`

  return (
    <div className={clsx(className, styles.container)} style={style}>
      <div
        className={styles.wrapper}
        style={{ height: heightSize, width: widthSize }}
      >
        <img src={src} className={styles.img} alt="fake-img" />
      </div>
    </div>
  )
}

export default FakeImage
