import clsx from 'clsx'
import React from 'react'
import CameraConfirmFooter from '../../molecules/CameraConfirmFooter'
import CaptureOverlay from '../../organisms/CaptureOverlay'
import styles from './style.module.scss'

type CaptureConfirmProps = {
  src: string
  full?: boolean
  centerText: string
  rightText: string
  leftText: string
  onClickCenter: () => void
  onClickRight: () => void
  onClickLeft: () => void
}

type Props = CaptureConfirmProps & React.StyledProps

const CaptureConfirm: React.StyledFC<Props> = ({
  src,
  full,
  style,
  className,
  centerText,
  rightText,
  leftText,
  onClickCenter,
  onClickRight,
  onClickLeft,
}: Props) => {
  return (
    <div style={style} className={clsx(className, styles.container)}>
      <CaptureOverlay className={styles.overlay} src={src} full={full} />
      <div className={styles.wrapper}>
        <CameraConfirmFooter
          className={styles.footer}
          centerText={centerText}
          rightText={rightText}
          leftText={leftText}
          onClickCenter={onClickCenter}
          onClickLeft={onClickLeft}
          onClickRight={onClickRight}
        />
      </div>
    </div>
  )
}

export default CaptureConfirm
